import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { FilterRealClub } from 'src/app/classes/filterRealClub';
import { Pagination } from 'src/app/classes/pagination';
import { RealCompetition } from 'src/app/classes/real_competition';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { RealClubService } from 'src/app/services/real-club.service';
import { RealCompetitionService } from 'src/app/services/real-competition.service';

@Component({
  selector: 'app-realclub-list',
  templateUrl: './realclub-list.component.html',
  styleUrls: ['./realclub-list.component.scss']
})
export class RealclubListComponent implements OnInit {

  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  list: any;
  faPlus = faPlusCircle;
  faTrash = faTrashAlt;

  cols: string[];
  searchForm = this.fb.group({});
  listPagination: Pagination;
  unsubscribe$: Subscription;

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;
  filterRealClub: FilterRealClub;
  realCompetition_list: RealCompetition[];
  maxSize = 15;

  constructor(private router: Router, public realClubService: RealClubService, public realCompetitionService: RealCompetitionService, private fb: FormBuilder) {
    this.pathList = [['Real Clubs', 'real_club/list']];
  }

  formControlsNames(form) {
    return Object.keys(form);
  }

  add(a: Alert) {
    this.alertbox1.alerts.push(a);
  }

  page_nr($event) {
    this.searchForm.controls['ctrl_page'].setValue($event);
    this.getList();
  }

  getList() {
    let filter: Array<any> | null = null;
    if (this.searchForm.value) {
      filter = [];
    }

    for (const key in this.searchForm.value) {
      if (Object.prototype.hasOwnProperty.call(this.searchForm.value, key)) {
        const element = this.searchForm.value[key];
        if ((element || element == '') && element != 'null') {
          const itemKey = key.split('_')
          itemKey.shift();
          filter[itemKey.join('_')] = element;
          this.filterRealClub['ctrl_' + itemKey.join('_')] = element;
        }
      }
    }
    this.realClubService.setFilterObs(this.filterRealClub);

    filter['limit'] = this.maxSize;
    if (filter['realclub_id'] == '0') {
      delete filter['realclub_id'];
    }
    this.busy = this.realClubService.list2(filter).subscribe({
      next: (data) => {
        this.list = data.payload;
        this.listPagination = data.pagination;
        // this.cols = data.admin_info.list_cols ?? data.admin_info.cols;
        // for (const col of this.cols) {
        //   this.searchForm.addControl('ctrl_' + col, new FormControl('', Validators.required))
        // }
      },
      error: (err) => console.log('ERRORE: ' + JSON.stringify(err.error.description)),
    });
  }

  deleteItem(id: number) {
    if (+id > 0) {
      this.busy = this.realClubService.delete(id).subscribe({
        next: () => this.getList(),
        error: (err) => console.log(err),
      });
    }
  }

  navigateTo(id: any) {
    this.router.navigate([`/real_club/${id}`]);
  }

  dialogConfirm(id) {
    console.log(id);
    if (+id > 0) {
      this.deleteItem(id);
    }
  }

  ngOnInit(): void {
    this.filterRealClub = new FilterRealClub();

    this.cols = ['id', 'description', 'realcompetition_id'];
    this.searchForm.addControl('ctrl_page', new FormControl('', Validators.required));
    for (const col of this.cols) {
      this.searchForm.addControl('ctrl_' + col, new FormControl('', Validators.required))
    }
    const $rclubs = this.realCompetitionService.list2().subscribe(rcompetitions => {
      this.realCompetition_list = rcompetitions.payload.sort((a, b) => (a.description < b.description ? -1 : 1));
    });

    this.unsubscribe$ = this.realClubService.getFilterObs().subscribe(filter => {
      this.filterRealClub = filter;
      this.searchForm.controls['ctrl_page'].setValue(this.filterRealClub.ctrl_page);
      this.searchForm.controls['ctrl_id'].setValue(this.filterRealClub.ctrl_id);
      this.searchForm.controls['ctrl_description'].setValue(this.filterRealClub.ctrl_description);
      this.searchForm.controls['ctrl_realcompetition_id'].setValue(this.filterRealClub.ctrl_realcompetition_id);
    });

    this.getList();
  }

}
