import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterRealPlayer } from '../classes/filterRealPlayer';

@Injectable({
  providedIn: 'root'
})
export class RealPlayerService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
    this.entity = 'realplayer';
  }

  private filterObs$: BehaviorSubject<FilterRealPlayer> = new BehaviorSubject(new FilterRealPlayer());

  getFilterObs(): Observable<FilterRealPlayer> {
      return this.filterObs$.asObservable();
  }

  setFilterObs(filter: FilterRealPlayer) {
      this.filterObs$.next(filter);
  }
}
