import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Subscription, forkJoin } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { BaseClass } from 'src/app/classes/base_class';
import { RealPlayer, RealPlayerResponse } from 'src/app/classes/real_player';
import { AbstractService } from 'src/app/services/abstract.service';
import { AlertboxComponent } from '../alertbox/alertbox.component';
import { RealClubService } from 'src/app/services/real-club.service';
import { RealClub } from 'src/app/classes/real_club';
import { RealCompetitionService } from 'src/app/services/real-competition.service';
import { RealCompetition } from 'src/app/classes/real_competition';

@Component({
  selector: 'app-single-player',
  templateUrl: './single-player.component.html',
  styleUrls: ['./single-player.component.scss']
})
export class SinglePlayerComponent implements OnInit {
  busy: Subscription;
  faPlus = faSave;
  rels: Array<any>;
  singleResponse: RealPlayerResponse;
  cols: any;

  @Input() id: number;
  @Input() single: RealPlayer;
  @Input() componentService: AbstractService;
  @Input() routePart: string;
  @Input() routeSingleDescription: string;
  @Output() pathListEvent = new EventEmitter<any[]>();
  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;
  real_clubs: RealClub[];
  option: number = 0;
  realcompetitions: RealCompetition[];

  constructor(private router: Router, public realclubService: RealClubService, public realcompetitionService: RealCompetitionService) { }

  ngOnInit(): void {
    this.getSingle(this.id);
    this.realcompetitionService.list2().subscribe(x => {
      this.realcompetitions = x.payload;
    });
  }

  getControlType(control_name: string): string {
    if (control_name in this.single.controls) {
      return this.single.controls[control_name];
    }
    return '';
  }

  changeClub($event) {
    this.option = $event;
    this.single.realclub_id = $event;
  }

  changeRuoliSpecifici($event) {
    this.single.ruoli_specifici = $event;
  }

  isChecked = [];

  onSelectTab(value) {
    console.log("Tab selected", value)
  }

  onSelectResult(value) {
    let ruoli_specifici = '';
    for (const [index, value] of this.isChecked.entries()) {
      switch (index) {
        case 0:
          if (value && value != null || value == true) ruoli_specifici += 'P ';
          break;
        case 1:
          if (value && value != null || value == true) ruoli_specifici += 'DC ';
          break;
        case 2:
          if (value && value != null || value == true) ruoli_specifici += 'DD ';
          break;
        case 3:
          if (value && value != null || value == true) ruoli_specifici += 'DS ';
          break;
        case 4:
          if (value && value != null || value == true) ruoli_specifici += 'CC ';
          break;
        case 5:
          if (value && value != null || value == true) ruoli_specifici += 'CF ';
          break;
        case 6:
          if (value && value != null || value == true) ruoli_specifici += 'W ';
          break;
        case 7:
          if (value && value != null || value == true) ruoli_specifici += 'T ';
          break;
        case 8:
          if (value && value != null || value == true) ruoli_specifici += 'PC ';
          break;
        case 9:
          if (value && value != null || value == true) ruoli_specifici += 'A ';
          break;


        default:
          break;
      }
    }
    this.single.ruoli_specifici = ruoli_specifici;
  }

  onCheckboxChanged(evt) {
    console.log("onCheckboxChanged", this.isChecked);

    evt.stopPropagation();
  }

  changeRuolo($event) {
    this.single.ruolo = $event;
  }


  getSingle(id: number) {
    const isNew = id == 0 ? true : false;
    if (id == 0) id = 1;
    let filter: Array<any> = [];
    filter['limit'] = 30;
    filter['realcompetition_id'] = 6;
    const $rclub1 = this.realclubService.list2(filter);
    const $player = this.componentService.get(id);
    this.busy = forkJoin([$rclub1, $player]).subscribe(([rclub, data]) => {
      this.real_clubs = rclub.payload;
      if (!isNew) {
        for (const key in data.payload) {
          if (Object.prototype.hasOwnProperty.call(this.single, key)) {
            this.single[key] = data.payload[key];
          }
        }
        let ruoli_specifici = [];
        ruoli_specifici = this.single.ruoli_specifici.replace(',', ' ').replace(/\s\s+/g, ' ').split(' ');
        for (const value of ruoli_specifici) {
          switch (value) {
            case 'P':
              this.isChecked[0] = true;
              break;
            case 'DC':
              this.isChecked[1] = true;
              break;
            case 'DD':
              this.isChecked[2] = true;
              break;
            case 'DS':
              this.isChecked[3] = true;
              break;
            case 'CC':
              this.isChecked[4] = true;
              break;
            case 'CF':
              this.isChecked[5] = true;
              break;
            case 'W':
              this.isChecked[6] = true;
              break;
            case 'T':
              this.isChecked[7] = true;
              break;
            case 'PC':
              this.isChecked[8] = true;
              break;
            case 'A':
              this.isChecked[9] = true;
              break;

            default:
              break;
          }
        }
      }
      this.option = this.single.realclub_id ? this.single.realclub_id : 0;

      this.cols = data.admin_info.cols.filter((c) => c.indexOf('.') == -1); // rimuovo i campi fk
      let path_part_1 = '';
      if (Object.prototype.hasOwnProperty.call(this.single, 'calciatore')) {
        path_part_1 = this.single['calciatore'];
      } else if (Object.prototype.hasOwnProperty.call(this.single, 'indirizzo_email')) {
        path_part_1 = this.single['indirizzo_email'];
      } else if (Object.prototype.hasOwnProperty.call(this.single, 'description')) {
        path_part_1 = this.single['description'];
      } else {
        path_part_1 = this.single['id'].toString();
      }
      this.pathListEvent.emit([path_part_1.toString(), `${this.routePart}/${this.single.id}`]);

    });


  }

  getRels(rel: string) {
    // this.httpdata.get('/' + rel).subscribe(
    //   (data) => {
    //     this.rels.push({ class_name: rel, list: data });
    //   },
    //   (err) => {
    //     console.error(err);
    //     alert('ERRORE: ' + JSON.stringify(err.error.description));
    //   }
    // );
  }

  getRelated(rel: string) {
    // return this.rels.find((r) => {
    //   return r.class_name == rel;
    // }).list;
  }

  saveItem() {
    const payload = BaseClass.cleanup(this.single);

    if (this.single.id == 0) {
      this.busy = this.componentService.create(payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'normal';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
          this.router.navigate([`/${this.routePart}/list`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    } else {
      this.busy = this.componentService.update(this.single.id, payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "Salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'primary';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert);
          // this.router.navigate([`/${this.routePart}/${this.single.id}`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    }
  }

  getRealCompetitionByClubID(clubID: number): RealCompetition {
    // console.log(clubID);
    let o: RealCompetition = null;
    if (clubID > 0) {
      let rclub = this.real_clubs.find(c => c.id == clubID);
      o = rclub.real_competition;

    }
    return o;
  }
}
