<span class="fix-header card-no-border {{minimenu}}" [class.show-sidebar]="showMenu%2==0" (window:resize)="onResize($event)">
  <div id="main-wrapper">
    
    <header class="topbar">
      <nav class="navbar top-navbar navbar-dark">
        
        <a class="navbar-brand" href="#" routerLink="/dashboard">
          <img src="/assets/images/logo-bianco_100x30.png">
        </a>

        <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
          
          <!--<button class="navbar-toggler" type="button" aria-controls="main-nav" aria-expanded="false" aria-label="">
            <span class="navbar-toggler-icon d-flex d-lg-none" [routerLink]="['dashboard']"  [ngClass]="{ 'active': activeComponent == 'dashboard' }">
            </span>
          </button>-->

          <button type="button" data-toggle="collapse" data-target="#sidebarMenu" class="navbar-toggler" (click)="isCollapsed = !isCollapsed" aria-expanded="false">
            <span class="navbar-toggler-icon d-flex d-lg-none">
            </span>
          </button>

          <button dropdownToggle type="button" class="btn btn-light dropdown-toggle">
            <fa-icon [icon]="faUser"></fa-icon>
            <span class="username-on-topbar">&nbsp;&nbsp;{{userlogged}}</span>&nbsp;<b class="caret"></b>
          </button>
          
          <ul *dropdownMenu class="dropdown-menu rounded-bottom" role="menu">
            <!-- <li role="menuitem"><a class="dropdown-item" href="#">Action</a></li> -->
            <!-- <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li> -->
            <!-- <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li> -->
            <!-- <li class="divider dropdown-divider"></li> -->
            <!-- <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a> -->
            <li role="menuitem"><a class="dropdown-item" (click)='logout()'> <i class="fas fa-sign-out-alt"></i> Logout</a> </li>
          </ul>
        
        </div>
      </nav>
    </header>

    <div class="page-wrapper">
      <div class="container-fluid sicard">
        
          <div class="row">
            <div class="aside col-xl-2 col-md-3 collapse" id="#sidebarMenu" [collapse]=isCollapsed>
              <nav class="sidebar-nav"> <!-- sticky-top -->

                <ul id="sidebar-nav" class="sidebar-nav-justify-mobile">

                  <li>
                    <h5>Stagione 2023-2024</h5>
                  </li>

                  <hr>
                  
                  <li>
                    <a aria-expanded="false" [routerLink]="['real_competition/list']" [ngClass]="{ 'active': activeComponent == 'real_league' }">
                      <!-- [ngClass]="{ 'active': foto_visibile }" -->
                      <i class="fas fa-futbol fa-fw"></i>Real Competitions
                    </a>
                  </li>

                  
                  <li>
                    <a aria-expanded="false" [routerLink]="['real_club/list']" [ngClass]="{ 'active': activeComponent == 'real_club' }">
                      <!-- [ngClass]="{ 'active': video_visibile }" -->
                      <i class="fas fa-shield-alt fa-fw"></i>Real Clubs
                    </a>
                  </li>

                  <li>
                    <a aria-expanded="false" [routerLink]="['real_player/list']" [ngClass]="{ 'active': activeComponent == 'real_player' }">
                      <!-- [ngClass]="{ 'active': foto_visibile }" -->
                      <i class="fas fa-user-shield fa-fw"></i>Real Players
                    </a>
                  </li>

                  <li>
                    <a aria-expanded="false" [routerLink]="['real_match/list']" [ngClass]="{ 'active': activeComponent == 'real_match' }">
                      <!-- [ngClass]="{ 'active': staff_visibile }" -->
                      <i class="fas fa-running fa-fw"></i>Real Matches
                    </a>
                  </li>

                  <hr>

                  <li>
                    <a aria-expanded="false" class="has-arrow" [routerLink]="['user/list']" [ngClass]="{ 'active': activeComponent == 'user' }">
                      <!--  -->
                      <i class="fas fa-users fa-fw"></i>Utenti
                    </a>
                    <!-- <ul class="d-none d-lg-block second-level">
                      <li><a routerLink="." (click)="goTo(0)"><i class="fas fa-tag"></i>Titolo e Loghi</a></li>
                      <li><a routerLink="." (click)="goTo(1)"><i class="fas fa-tag"></i>Titolo e Loghi</a></li>
                      <li><a routerLink="." (click)="goTo(2)"><i class="fas fa-address-card"></i>Informazione Cliente</a></li>
                      <li><a routerLink="." (click)="goTo(3)"><i class="fas fa-map"></i>Mappa</a></li>
                      <li><a routerLink="." (click)="goTo(4)"><i class="fas fa-share-alt-square"></i>Social & Co.</a></li>
                      <li><a routerLink="." (click)="goTo(5)"><i class="fas fa-file-alt"></i>Contenuti</a></li>
                      <li><a routerLink="." (click)="goTo(6)"><i class="fas fa-map-pin"></i>Fuori Zona</a></li>
                      <li><a routerLink="." (click)="goTo(7)"><i class="fas fa-info-circle"></i>Altre info</a></li>
                    </ul> -->
                  </li>

                  
                </ul>
              </nav>
            </div>
            <div class="main col col-xl-10 col-md-9">
              <router-outlet></router-outlet>
              <footer class="footer">© Playermanager Srl! © - Tutti i diritti riservati</footer>
            </div>
          </div>
      </div>
      
    </div>
    
  </div>
</span>
