import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { GlobalService } from './services/global.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { UserService } from './services/user.service';
import { User } from './classes/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  faUser = faUser;
  isCollapsed = true;
  title = 'app';
  subscription: any;

  minimenu: string;
  showMenu = 0;
  userlogged = '';
  activeComponent = '';
  previous_w = 768;

  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService, private gs: GlobalService) {
    const w = window.innerWidth;
    this.minimenu = '';
    this.previous_w = w;
    if (w > 767) {
      this.isCollapsed = false;
    }
    else {
      this.isCollapsed = true;
    }
    
  }
  goTo(pos: number): void {}

  select(n: string) {
    this.activeComponent = n;
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  async logout() {
    localStorage.clear();
    await this.userService.logout();
    this.router.navigateByUrl('/login');
  }

  onResize(event) {

    const w = event.target.innerWidth;
    //console.log(w, this.previous_w);
    this.minimenu = '';
    if (this.previous_w > 767 && w < 768) {
      this.isCollapsed = true;
    } else if (this.previous_w < 768 && w > 767) {
      this.isCollapsed = false;
    }
    this.previous_w = w;
  }

  ngOnInit() {
    if (localStorage.getItem('isLoggedin') === 'false') {
      this.router.navigateByUrl('/login');
    }
    this.router.events.subscribe(async (event) => {
      // example: NavigationStart, RoutesRecognized, NavigationEnd
      // this.gs.navPush(this.router.url);
      if (event instanceof NavigationEnd) {
        this.activeComponent = event.url.split('/')[1];
        const p: User = this.userService.getCurrentUser();
        if (p) this.userlogged = p.indirizzo_email;

      }
    });

    this.subscription = this.gs.userEvent.subscribe((x) => {
      this.userlogged = x.username.toUpperCase();
    });
  }
}
