import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { AbstractService } from 'src/app/services/abstract.service';
import { AlertboxComponent } from '../alertbox/alertbox.component';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Pagination } from 'src/app/classes/pagination';

@Component({
  selector: 'app-list-paged',
  templateUrl: './list-paged.component.html',
  styleUrls: ['./list-paged.component.scss']
})
export class ListPagedComponent implements OnInit {
  busy: Subscription;

  faPencilAlt = faPencilAlt
  faTrashAlt = faTrashAlt

  @Input() list;
  @Input() paginationItems: Pagination;
  @Input() cols: string[];
  @Input() componentService: AbstractService;
  @Input() routePart: string;
  @Output() reloadList = new EventEmitter<string>();
  @Output() page = new EventEmitter<number>();
  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;

  returnedArray: string[];
  showBoundaryLinks: boolean = true;
  showDirectionLinks: boolean = true;
  rotate = false;
  maxSize = 5;

  constructor(private router: Router) { }

  ngOnInit(): void {  }


  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    if (this.list){
      this.returnedArray = this.list.slice(0, 50);
    }
  }


  pageChanged(event: PageChangedEvent): void {
    this.page.emit(event.page);

    // const startItem = (event.page - 1) * event.itemsPerPage;
    // const endItem = event.page * event.itemsPerPage;
    // this.returnedArray = this.list.slice(startItem, endItem);
 }

  add(a: Alert) {
    this.alertbox1.alerts.push(a);
  }
  
  deleteItem(id: number) {
    if (+id > 0) {
      this.busy = this.componentService.delete(id).subscribe({
        next: () => {
          this.reloadList.emit();
        },
        error: (err) => console.log(err),
      });
    }
  }

  navigateTo(id: any) {
    this.router.navigate([`/${this.routePart}/${id}`]);
  }

  dialogConfirm(id) {
    console.log(id);
    if (+id > 0) {
      this.deleteItem(id);
    }
  }

}
