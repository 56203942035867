import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { RealPlayer, RealPlayerResponse } from 'src/app/classes/real_player';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { RealPlayerService } from 'src/app/services/real-player.service';

@Component({
  selector: 'app-realplayer-single',
  templateUrl: './realplayer-single.component.html',
  styleUrls: ['./realplayer-single.component.scss']
})
export class RealplayerSingleComponent implements OnInit {
  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  id: number;
  single: RealPlayer;
  cols: any;
  rels: Array<any>;
  faPlus = faSave;

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;

  constructor(private ar: ActivatedRoute, public realPlayerService: RealPlayerService, private router: Router) {
    this.pathList = [['Real Players', 'real_player/list', null]];
  }

  loga($event) {
    this.pathList.push($event);
  }

  ngOnInit() {
    this.rels = [];
    this.id = parseInt(this.ar.snapshot.paramMap.get('id'), 10);
    this.single = new RealPlayer();
  }

  // getSingle(id: number) {
  //   const isNew = id == 0 ? true : false;
  //   if (id == 0) id = 1;
  //   this.busy = this.realPlayerService.get(id).subscribe(
  //     (data: RealPlayerResponse) => {
  //       if (isNew) {
  //         this.single = new RealPlayer();
  //       } else {
  //         // this.single = data.payload;
  //         this.single = new RealPlayer();
  //         for (const key in data.payload) {
  //           if (Object.prototype.hasOwnProperty.call(this.single, key)) {
  //             this.single[key] = data.payload[key];
  //             this.pathList = [['Real Player', 'real_player/list', null]];
  //           }
  //         }
  //       }

  //       this.cols = data.admin_info.cols.filter((c) => c.indexOf('.') == -1); // rimuovo i campi fk
  //       this.pathList.push([this.single.calciatore, 'user/' + this.id]);
  //       // for (const iterator of data.admin_info.rels) {
  //       //   this.getRels(iterator);
  //       // }
  //     },
  //     (err: HttpErrorResponse) => {
  //       // alert('ERRORE: ' + JSON.stringify(err.error.description));
  //       const provaAlert: Alert = new Alert();
  //       provaAlert.msg = err.error.status + ' - ' + err.error.payload;
  //       provaAlert.timeout = 5000;
  //       provaAlert.type = 'danger';
  //       this.alertbox1.alerts = [];
  //       this.alertbox1.alerts.push(provaAlert)
  //     }
  //   );
  // }

  getRels(rel: string) {
    this.realPlayerService.getRels(rel).subscribe(
      (data) => {
        this.rels.push({ class_name: rel, list: data });
      },
      (err) => {
        console.error(err);
        alert('ERRORE: ' + JSON.stringify(err.error.description));
      }
    );
  }

  getRelated(rel: string) {
    return this.rels.find((r) => {
      return r.class_name == rel;
    }).list;
  }

  saveItem() {
    const payload = RealPlayer.cleanup(this.single);

    if (this.id == 0) {
      this.busy = this.realPlayerService.create(payload).subscribe({
        next: () => this.router.navigate([`/real_player/list`]),
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    } else {
      this.busy = this.realPlayerService.update(this.id, payload).subscribe({
        next: () => this.router.navigate([`/real_player/${this.id}`]),
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    }
  }

}
