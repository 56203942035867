import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() bcRight: Array<Array<number>> = [];
  @Input('pathList') pathListValue: Array<Array<string>> = [];

  constructor() { }

  ngOnInit(): void {
  }

  getTitle(): string {
    if (this.pathListValue[this.pathListValue.length -1]){
      return this.pathListValue[this.pathListValue.length -1][0];
    }
    return "";
  }
}
