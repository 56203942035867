// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const apiBase = 'https://playermanager.studioitc.com/api';
const apiBase = 'https://fleur.playermanager.club/api';
// const apiBase = 'http://127.0.0.1:8080/api';

export const environment = {
  production: false,
  basePath: `${apiBase}`,
  MY_TOKEN: 'myToken',
  CLIENT_ID_KEYWORD: 'clientId',
  DOMAIN: `${apiBase}/api`,
  SERVER_API_PATH: `${apiBase}`
//   SERVER_USER_PATH: `${apiBase}/mini_user`,
//   SERVER_LOGIN_PATH: `${apiBase}/login`,
//   SERVER_LOGOUT_PATH: `${apiBase}/logoutfe`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
