import { adminInfo } from './adminInfo';
import { BaseClass } from './base_class';
import { BaseInterface } from './base_interface';
import { Pagination } from './pagination';
export class RealPlayer extends BaseClass implements BaseInterface {

  constructor() {
    super();
    this.id = 0;
    this.calciatore = "";
    this.shirtNumber = 0;
    this.nazionalita = "";
    this.ruolo = "";
    this.ruoli_specifici = "";
    this.data_nascita = null;
    this.tiratore_angoli = 0;
    this.tiratore_punizioni_indirette = 0;
    this.tiratore_punizioni = 0;
    this.tiratore_rigori = 0;
    this.titolarita_potenziale = 0;
    this.vdm_transfermarkt = 0;
    this.vdm_pmc = 0;
    this.ingaggio_pmc = 0
    this.presenze_da_titolare = 0;
    this.presenze_da_panchina = 0;
    this.media_voto = 0;
    this.fantasy_media = 0;
    this.gialli = 0;
    this.rossi = 0;
    this.gol = 0;
    this.assist = 0;
    this.rigori_parati = 0;
    this.gol_subiti = 0;
    this.autogol = 0;
    this.punteggio_iniziale = 0;
    this.puteggio_progressivo = 0;
    this.realclub_id = 0;
    this.created_at = null;
    this.updated_at = null;
    this.deleted_at = null;

    this.ctrl_convocato = true;
    
    this.controls = {
      id: "number",
      calciatore: "string",
      shirtNumber: "number",
      nazionalita: "string",
      ruolo: "string",
      ruoli_specifici: "string",
      data_nascita: "Date",
      tiratore_angoli: "number",
      tiratore_punizioni_indirette: "number",
      tiratore_punizioni: "number",
      tiratore_rigori: "number",
      titolarita_potenziale: "number",
      vdm_transfermarkt: "number",
      vdm_pmc: "number",
      ingaggio_pmc: "number",
      presenze_da_titolare: "number",
      presenze_da_panchina: "number",
      media_voto: "number",
      fantasy_media: "number",
      gialli: "number",
      rossi: "number",
      gol: "number",
      assist: "number",
      rigori_parati: "number",
      gol_subiti: "number",
      autogol: "number",
      punteggio_iniziale: "number",
      puteggio_progressivo: "number",
      created_at: "Date",
      updated_at: "Date",
      deleted_at: "Date",
    }
  }

  controls: any = {
    id: "number",
    calciatore: "string",
    shirtNumber: "number",
    nazionalita: "string",
    ruolo: "string",
    ruoli_specifici: "string",
    data_nascita: "Date",
    tiratore_angoli: "number",
    tiratore_punizioni_indirette: "number",
    tiratore_punizioni: "number",
    tiratore_rigori: "number",
    titolarita_potenziale: "number",
    vdm_transfermarkt: "number",
    vdm_pmc: "number",
    ingaggio_pmc: "number",
    presenze_da_titolare: "number",
    presenze_da_panchina: "number",
    media_voto: "number",
    fantasy_media: "number",
    gialli: "number",
    rossi: "number",
    gol: "number",
    assist: "number",
    rigori_parati: "number",
    gol_subiti: "number",
    autogol: "number",
    punteggio_iniziale: "number",
    puteggio_progressivo: "number",
    created_at: "Date",
    updated_at: "Date",
    deleted_at: "Date",
  }

  id: number;
  calciatore: string;
  shirtNumber: number;
  nazionalita: string;
  ruolo: string;
  ruoli_specifici: string;
  piede: string;
  data_nascita: Date;
  tiratore_angoli: number;
  tiratore_punizioni_indirette: number;
  tiratore_punizioni: number;
  tiratore_rigori: number;
  titolarita_potenziale: number;
  vdm_transfermarkt: number;
  vdm_pmc: number;
  ingaggio_pmc: number;
  presenze_da_titolare: number;
  presenze_da_panchina: number;
  media_voto: number;
  fantasy_media: number;
  gialli: number;
  rossi: number;
  gol: number;
  assist: number;
  rigori_parati: number;
  gol_subiti: number;
  autogol: number;
  punteggio_iniziale: number;
  puteggio_progressivo: number;
  realclub_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;

  ctrl_convocato: boolean;

}

export class RealPlayerResponse {
  payload: RealPlayer;
  admin_info: adminInfo;
  pagination: Pagination;
}
