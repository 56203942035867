import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RealMatch } from 'src/app/classes/real_match';
import { RealMatchService } from 'src/app/services/real-match.service';

@Component({
  selector: 'app-realmatch-single',
  templateUrl: './realmatch-single.component.html',
  styleUrls: ['./realmatch-single.component.scss']
})
export class RealmatchSingleComponent implements OnInit {
  pathList: string[][];
  single: RealMatch;
  id: number;
  rels: any[];
  busy: Subscription;
 
  constructor(private ar: ActivatedRoute, private router: Router, public realClubService: RealMatchService) {
    this.pathList = [['Real Matches', 'real_match/list']];
  }
  
  pathList$($event) {
    this.pathList.push($event);
  }

  ngOnInit(): void {
    this.rels = [];
    this.id = parseInt(this.ar.snapshot.paramMap.get('id'), 10);
    this.single = new RealMatch();
  }
}
