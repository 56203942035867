export class FilterRealPlayer {
    ctrl_page: number;
    ctrl_id: number;
    ctrl_realclub_id;
    ctrl_calciatore: string;
    ctrl_nazionalita: string;
    ctrl_ruolo: string;
    ctrl_data_nascita: string;
    constructor(){
        this.ctrl_realclub_id = 0;
    }
}