import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse, ApiResponse2 } from '../classes/apiResponse';
import { User, UserResponse } from '../classes/user';
import { AbstractService } from './abstract.service';
import { RealPlayer } from '../classes/real_player';

@Injectable({
  providedIn: 'root',
})
export class BaseService implements AbstractService{
  public api: string;
  public strEntity: string;
  constructor(protected http: HttpClient) {
    this.api = environment.SERVER_API_PATH;
  }

  public set entity(v: string) {
    this.strEntity = v;
  }

  public get entity(): string {
    return this.strEntity;
  }

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  get(id: number): Observable<any> {
    return this.http.get<any[]>(`${this.api}/${this.strEntity}/${id}`, this.options);
  }

  getRels(rel: string): Observable<any> {
    return this.http.get<any[]>(`${this.api}/${rel}`, this.options);
  }

  list(): Observable<ApiResponse<User | any>> {
    return this.http.get<ApiResponse<User | any>>(`${this.api}/${this.strEntity}`, this.options);
  }

  list2(filter: Array<any> | null = null): Observable<ApiResponse2< RealPlayer | User | any>> {
    let pstring = '';
    let params = new URLSearchParams();
    let myOptions = {};
    if (filter) {
      myOptions['params'] = [];
      for (const key in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, key)) {
          const element = filter[key];
          if (element !== ''){
            params.append(key, element);
            myOptions['params'][key]= element;
          }
        }
      }
      // console.log(params.toString());
      // pstring = '?' + params;
      // myOptions['params'] = {
      //   id: '5'
      // };

    }
    return this.http.get<ApiResponse2< RealPlayer | User | any>>(`${this.api}/${this.strEntity}`, myOptions);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.api}/${this.strEntity}/${id}`, this.options);
  }

  update(id: number, record): Observable<any> {
    const body = JSON.stringify(record);
    return this.http.patch(`${this.api}/${this.strEntity}/${id}`, body, this.options);
  }

  create(record: object) {
    const body = JSON.stringify(record);
    return this.http.post(`${this.api}/${this.strEntity}`, body, this.options);
  }

  // getRels(rel: string): Observable<any> {
  //   this.http.get('/' + rel).subscribe(
  //     (data) => {
  //       return ({ class_name: rel, list: data });
  //     },
  //     (err) => {
  //       console.error(err);
  //       alert('ERRORE: ' + JSON.stringify(err.error.description));
  //     }
  //   );
  // }
}
