<app-breadcrumb [pathList]="pathList"></app-breadcrumb>
<div [ngBusy]="{busy: busy, message: 'Loading...', backdrop: true, delay: 0, minDuration: 10}"></div>
<div class="container-fluid">
    <div class="row d-flex justify-content-between" [formGroup]="searchForm">
        <div class="input-group input-group-sm  mb-1 item d-flex justify-content-start filtering">
            <select [formControlName]="'ctrl_realclub_id'" class="form-select" id="inputGroupSelect01" (change)="getList()"  >
                <option value="0" selected>Scegli il Club...</option>
                <option *ngFor="let rclub of realClub_list" [value]="rclub.id" [selected]="rclub.id == searchForm.value['ctrl_realclub_id']" > {{rclub.common_mame}}</option>    
            </select>
            <!-- {{realClub_list|json}} -->
            <input type="hidden" [formControlName]="'ctrl_page'" class="form-control">
            <!-- <input type="hidden" [formControlName]="'ctrl_realclub_id'" class="form-control" placeholder="ctrl_realclub_id"> -->
            <input type="text" [formControlName]="'ctrl_id'" class="form-control" placeholder="id">
            <input type="text" [formControlName]="'ctrl_calciatore'" class="form-control" placeholder="calciatore">
            <!-- <input type="text" [formControlName]="'ctrl_nazionalita'" class="form-control" placeholder="nazionalita"> -->
            <input type="text" [formControlName]="'ctrl_ruolo'" class="form-control" placeholder="ruolo">
            <!-- <input type="text" [formControlName]="'ctrl_data_nascita'" class="form-control" placeholder="data nascita"> -->
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="getList()">Filtra</button>
            </div>
        </div>
        <div class="input-group input-group-sm mb-1 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="navigateTo(0)">
                    <fa-icon [icon]="faPlus" [spin]="false"></fa-icon> Aggiungi
                </button>
            </div>
        </div>
    </div>
    <app-list-realplayer [list]="list" [paginationItems]="listPagination" [cols]="cols_list" [componentService]="realPlayerService" [routePart]="'real_player'"
        (page)="page_nr($event)" (reloadList)="getList()"></app-list-realplayer>
    <app-alertbox #alertbox1 [alerts]=""></app-alertbox>
</div>

<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)">asd</app-confirmation-dialog>