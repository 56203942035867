<app-breadcrumb [pathList]="pathList"></app-breadcrumb>
<div [ngBusy]="{busy: busy, message: 'Loading...', backdrop: true, delay: 0, minDuration: 0}"></div>
<div class="container-fluid">
    <!-- {{matchdays_pl|json}} -->
    <div class="row d-flex justify-content-between" [formGroup]="searchForm">

        <div class="input-group input-group-sm  mb-1 item d-flex justify-content-start filtering">
            <select [formControlName]="'ctrl_realcompetition_id'" class="form-select" id="inputGroupSelect01" (change)="realcometitionpage($event.target.value)"  >
                <option selected value="0" selected>Scegli la competizione...</option>
                <option *ngFor="let rcomp of realCompetition_list" [value]="rcomp.id" [selected]="rcomp.id == searchForm.value['ctrl_realcompetition_id']" > {{rcomp.description}}</option>    
            </select>
            <select [formControlName]="'ctrl_matchday'" class="form-select" id="inputGroupSelect01" (change)="matchpage($event.target.value)">
                <option selected value="0">Scegli la giornata ...</option>
                <option *ngFor="let rmatchday of matchdays_pl" value="{{rmatchday.matchday}}" >
                    <span>
                        ({{rmatchday.matchday}}) {{rmatchday.cal_day|date:'dd/MM/yyyy '}} 
                    </span>
                    </option>   
            </select>
            <input type="hidden" [formControlName]="'ctrl_page'" class="form-control">
            <input type="hidden" [formControlName]="'ctrl_matchday'" class="form-control">
            <input type="text" [formControlName]="'ctrl_id'" class="form-control" placeholder="id">
            <input type="text" [formControlName]="'ctrl_realclubhome.description'" class="form-control" placeholder="squadra di casa">
            <input type="text" [formControlName]="'ctrl_realclubvisitor.description'" class="form-control" placeholder="squadra ospite">
            <input type="text" [formControlName]="'ctrl_status'" class="form-control" placeholder="status">
            <!-- <input type="text" [formControlName]="col" class="form-control" placeholder="{{col.split('_')[1]}}"
                aria-label="Filtra per " aria-describedby="basic-addon2" *ngFor="let col of formControlsNames(searchForm.controls)" > -->
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="getList()">Filtra</button>
            </div>
        </div>
        <div class="input-group input-group-sm mb-1 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="navigateTo(0)">
                    <fa-icon [icon]="faPlus" [spin]="false"></fa-icon> Aggiungi
                </button>
            </div>
        </div>
    </div>
    <app-list-realmatch [list]="list"  [cols]="cols" [componentService]="realMatchService" [routePart]="'real_match'" 
        (page)="page_nr($event)" (reloadList)="getList()" ></app-list-realmatch>
    <app-alertbox #alertbox1 [alerts]=""></app-alertbox>
</div>

<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)">asd</app-confirmation-dialog>