<div>
    <div class="table-responsive">
        <!-- <pagination *ngIf="list" [totalItems]="list.length" [itemsPerPage]="50" (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="rotate"
            [maxSize]="maxSize" class="d-flex justify-content-center"></pagination> -->
        <pagination *ngIf="list" [totalItems]="paginationItems.total" [itemsPerPage]="paginationItems.per_page" [(ngModel)]="paginationItems.current_page"
            (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
            [rotate]="rotate" [maxSize]="maxSize" class="d-flex justify-content-center"></pagination>
        <table class="table table-responsive table-striped table-hover table-list" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        <div class="truncate">
                            Descrizione
                        </div>
                    </th>
                    <th>
                        <div class="truncate">
                            Descrizione breve
                        </div>
                    </th>
                    <th>
                        <div class="truncate">
                            Competizione
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of returnedArray">
                    <td> {{item.id}} </td>
                    <td> {{item.description}} </td>
                    <td> {{item.common_mame}} </td>
                    <td> {{item.real_competition.description}} </td>
                    <td class="actions">
                        <button class="btn btn-app btn-sm btn-edit" type="button" (click)="navigateTo(item?.id)">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </button>
                        <button class="btn btn-app btn-sm btn-trash" type="button" (click)="one.openModal(item?.id)"
                            disabled>
                            <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- <pagination *ngIf="list" [totalItems]="list.length" [itemsPerPage]="50" (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="rotate"
            [maxSize]="maxSize" class="d-flex justify-content-center"></pagination> -->
        <pagination *ngIf="list" [totalItems]="paginationItems.total" [itemsPerPage]="paginationItems.per_page" [(ngModel)]="paginationItems.current_page"
            (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
            [rotate]="rotate" [maxSize]="maxSize" class="d-flex justify-content-center"></pagination>
    </div>
</div>
<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)"></app-confirmation-dialog>
<app-alertbox #alertbox1 [alerts]=""></app-alertbox>