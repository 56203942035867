import { adminInfo } from './adminInfo';
import { BaseClass } from './base_class';
import { BaseInterface } from './base_interface';

export class RealMatch extends BaseClass implements BaseInterface {

  constructor() {
    super();
    this.id = 0;
    this.description = "";
    this.realclub1_id = 0,
    this.realclub2_id = 0;
    this.real_competition_id = 0;
    this.realcalendarday_id = 0;
    this.tactic_module1_id = 0;
    this.tactic_module2_id = 0;
    this.fd_id = 0;
    this.matchday = 0;
    this.utcDate = null;
    this.status = "";
    this.minute = 0;
    this.attendance = 0;
    this.venue = "";
    this.score_winner = "";
    this.score_duration = "";
    this.score_halfTime = Object();
    this.score_fullTime = Object();
    this.score_extraTime = Object();
    this.score_penalties = Object();
    this.realclub1_lineup = [];
    this.realclub1_bench = [];
    this.realclub2_lineup = [];
    this.realclub2_bench = [];
    this.goals = [];
    this.bookings = [];
    this.substitutions = [];
    this.created_at = null;
    this.updated_at = null;
    this.deleted_at = null;
  }

  controls: any = {
    id: "number",
    description: "string",
    realclub1_id: "number",
    realclub2_id: "number",
    real_competition_id: "number",
    realcalendarday_id: "number",
    tactic_module1_id: "number",
    tactic_module2_id: "number",
    fd_id: "number",
    matchday: "number",
    utcDate: "Date",
    status: "string",
    minute: "number",
    attendance: "number",
    venue: "string",
    score_winner: "string",
    score_duration: "string",
    score_halfTime: "Object",
    score_fullTime: "Object",
    score_extraTime: "Object",
    score_penalties: "Object",
    realclub1_lineup: "Object",
    realclub1_bench: "Object",
    realclub2_lineup: "Object",
    realclub2_bench: "Object",
    goals: "Object",
    bookings: "Object",
    substitutions: "Object",
    created_at: "Date",
    updated_at: "Date",
    deleted_at: "Date",
  }

  id: number;
  description: string;
  realclub1_id: number;
  realclub2_id: number;
  real_competition_id: number;
  realcalendarday_id: number;
  tactic_module1_id: number;
  tactic_module2_id: number;
  fd_id: number;
  matchday: number;
  utcDate: Date;
  status: string;
  minute: number;
  attendance: number;
  venue: string;
  score_winner: string;
  score_duration: string;
  score_halfTime: Score;
  score_fullTime: Score;
  score_extraTime: Score;
  score_penalties: Score;
  realclub1_lineup: any[];
  realclub1_bench: any[];
  realclub2_lineup: any[];
  realclub2_bench: any[];
  goals: Goal[];
  bookings: Booking[];
  substitutions: Substitution[];
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;

}

class Description {
  id: number;
  name: string;
}

export class Substitution {
  minute: number;
  playerIn: Description;
  playerOut: Description;
  team: Description;
}

export class Goal {
  assist: string;
  extraTime: number;
  minute: number;
  scorer: Description;
  team: Description;
  type: string;
}

export class Score {
  awayTeam: String;
  away: String;
  homeTeam: String;
  home: String;
}

export class Booking {
  card: String;
  minute: String;
  player: any;
  team: any;
}

export class RealMatchResponse {
  payload: RealMatch;
  admin_info: adminInfo;
}
