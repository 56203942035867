import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { BaseClass } from 'src/app/classes/base_class';
import { RealCompetition, RealCompetitionResponse } from 'src/app/classes/real_competition';
import { User, UserResponse } from 'src/app/classes/user';
import { AbstractService } from 'src/app/services/abstract.service';
import { AlertboxComponent } from '../alertbox/alertbox.component';

@Component({
  selector: 'app-single-competition',
  templateUrl: './single-competition.component.html',
  styleUrls: ['./single-competition.component.scss']
})
export class SingleCompetitionComponent implements OnInit {
  busy: Subscription;
  faPlus = faSave;
  rels: Array<any>;
  singleResponse: UserResponse | RealCompetitionResponse ;
  cols: any;

  @Input() id: number;
  @Input() single: User | RealCompetition ;
  @Input() componentService: AbstractService;
  @Input() routePart: string;
  @Input() routeSingleDescription: string;
  @Output() pathListEvent = new EventEmitter<any[]>();
  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.getSingle(this.id);
  }

  getControlType(control_name: string): string {
    if (control_name in this.single.controls) {
      return this.single.controls[control_name];
    }
    return '';
  }

  getSingle(id: number) {
    const isNew = id == 0 ? true : false;
    if (id == 0) id = 1;
    this.busy = this.componentService.get(id).subscribe(
      (data: UserResponse | RealCompetitionResponse ) => {
        if (!isNew) {
          for (const key in data.payload) {
            if (Object.prototype.hasOwnProperty.call(this.single, key)) {
              this.single[key] = data.payload[key];
            }
          }
        }

        // this.cols = data.admin_info.cols.filter((c) => c.indexOf('.') == -1); // rimuovo i campi fk
        this.cols = [
          "id",
          "description",
          // "active",
          // "data_inizio",
          // "data_fine",
          "currentSeason_startDate",
          "currentSeason_endDate",
        ];
        let path_part_1 = '';
        if (Object.prototype.hasOwnProperty.call(this.single, 'calciatore')) {
          path_part_1 = this.single['calciatore'];
        } else if (Object.prototype.hasOwnProperty.call(this.single, 'indirizzo_email')) {
          path_part_1 = this.single['indirizzo_email'];
        } else if (Object.prototype.hasOwnProperty.call(this.single, 'description')) {
          path_part_1 = this.single['description'];
        } else {
          path_part_1 = this.single['id'].toString();
        }
        this.pathListEvent.emit([path_part_1.toString(), `${this.routePart}/${this.single.id}`]);
        // for (const iterator of data.admin_info.rels) {
        //   this.getRels(iterator);
        // }
      },
      (err) => {
        // console.error(err);
        console.log('ERRORE: ' + JSON.stringify(err.error.description));
      }
    );
  }

  getRels(rel: string) {
    // this.httpdata.get('/' + rel).subscribe(
    //   (data) => {
    //     this.rels.push({ class_name: rel, list: data });
    //   },
    //   (err) => {
    //     console.error(err);
    //     alert('ERRORE: ' + JSON.stringify(err.error.description));
    //   }
    // );
  }

  getRelated(rel: string) {
    // return this.rels.find((r) => {
    //   return r.class_name == rel;
    // }).list;
  }

  saveItem() {
    const payload = BaseClass.cleanup(this.single);

    if (this.single.id == 0) {
      this.busy = this.componentService.create(payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'normal';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
          this.router.navigate([`/${this.routePart}/list`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    } else {
      this.busy = this.componentService.update(this.single.id, payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "Salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'primary';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert);
          // this.router.navigate([`/${this.routePart}/${this.single.id}`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    }
  }

}
