<div class="row d-flex justify-content-between">
    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
    </div>
    <div class="input-group input-group-sm mb-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <i class="fas fa-save"></i>
                Salva
            </button>
        </div>
    </div>
</div>

<div class="pr-3 pl-3 mb-5">
    <div class="table-responsive">
        <table class="table table-hover table-sm table-edit" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th scope="col" class="col-3">Descrizione</th>
                    <th scope="col" class="col-9">Valore</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ID</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['id']" id="id" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Descrizione estesa</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="single['description']" id="description">
                    </td>
                </tr>
                <tr>
                    <td>Descrizione breve</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="single['common_mame']" id="common_mame">
                    </td>
                </tr>
                <tr>
                    <td>Url del logo</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="single['crestUrl']" id="crestUrl">
                    </td>
                </tr>
                <tr>
                    <td>Competizione principale</td>
                    <td>
                        <select class="form-select" id="inputGroupSelect01" [(ngModel)]="option" (change)="changeCompetition($event.target.value)">
                            <option value="0">Scegli la Competizione...</option>
                            <option *ngFor="let real_competition of real_competitions" [value]="real_competition.id" >
                                {{real_competition.description}}
                            </option>   
                        </select>
                    </td>
                </tr>

                <!-- <tr>
                    <td>stage</td>
                    <td>
                        <input class="form-control form-control-sm" [(ngModel)]="single['stage']" id="stage">
                    </td>
                </tr> -->


                <tr *ngFor="let r of rels ">
                    <!-- <td>
                    <div class="form-check"><input type="checkbox" class="form-check-input"></div>
                    </td> -->
                    <td>{{r.class_name | uppercase}}</td>
                    <td>
                        <!-- <select id="input_{{r.class_name}}" class="form-control"  [(ngModel)]="single[r.class_name + '_id']">
                            <option value='-1'>... </option>
                            <option *ngFor="let opt of getRelated(r.class_name).list "  [ngValue]="opt.id" [attr.selected]="opt.id == single[r.class_name + '_id']? 'checked' : null">
                                {{opt.description}}</option>
                        </select> -->

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<app-alertbox #alertbox1 [alerts]=""></app-alertbox>