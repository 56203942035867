import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpDataService } from 'src/app/services/http-data.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/classes/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  router: Router;
  txtBtnAccedi = 'Accedi';
  loggingClass = 'btn btn-info btn-block';
  loginError = false;
  @ViewChild('myDiv') myDiv: ElementRef;
  busy: Promise<any>;

  constructor(public httpData: HttpDataService, private userService: UserService, router: Router, public globals: GlobalService) {
    this.router = router;
  }

  logout() {
    this.router.navigateByUrl('/login');
    window.location.reload();
  }

  authenticate(username: string, password: string) {
    this.loggingClass = 'btn btn-info btn-block disabled';
    this.txtBtnAccedi = 'Verifica ...';
    this.userService.login(username, password).subscribe({
      next: () => {
        this.router.navigateByUrl('/dashboard');
      },
      error: (err) => {
        this.loginError = true;
        setTimeout(() => {
          this.loginError = false;
          this.loggingClass = 'btn btn-info btn-block';
          this.txtBtnAccedi = 'Accedi';
        }, 3500);
        localStorage.setItem('isLoggedin', 'false');
        localStorage.setItem('userLoggedin', '');
        this.globals.setSessionId('');
        this.globals.userChange(localStorage.getItem('userLoggedin'), new Date(localStorage.getItem('dateIn')));
        // this.logged = localStorage.getItem('isLoggedin');
        this.txtBtnAccedi = 'Accesso Fallito';
        localStorage.clear();
      },
    });

    setTimeout(() => {
      this.txtBtnAccedi = 'Accedi';
    }, 3500);
    // console.log('ko');
    // this.globals.displayMessage('login fallito', 'danger');
    // localStorage.setItem('isLoggedin', 'false');
    // localStorage.setItem('userLoggedin', '');
    // this.globals.setSessionId('');
  }

  isLogged() {
    return this.httpData.isLogged();
  }

  ngOnInit() {
    if (localStorage.getItem('isLoggedin')) {
      this.router.navigateByUrl('/dashboard');
    }
  }
}
