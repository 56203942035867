import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpDataService } from 'src/app/services/http-data.service';
// import { Observable, throwError } from 'rxjs';
// import { catchError, retry } from 'rxjs/operators';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Alert } from 'src/app/classes/alert';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  list: any;
  faPlus = faPlusCircle;
  faTrash = faTrashAlt;

  cols: string[];
  searchForm = this.fb.group({});

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;

  constructor(private router: Router, public userService: UserService, private fb: FormBuilder) {
    this.pathList = [['Utenti', 'user/list']];
  }
  
  add(a: Alert) {
    this.alertbox1.alerts.push(a);
  }

  formControlsNames(form) {
    return Object.keys(form);
  }

  getList(sf = null) {
    // console.log(this.searchForm.value);
    let filter: Array<any> | null = null;
    if(this.searchForm.value) {
      filter = [];
    }
    for (const key in this.searchForm.value) {
      if (Object.prototype.hasOwnProperty.call(this.searchForm.value, key)) {
        const element = this.searchForm.value[key];
        const itemKey = key.split('_')[1]
        filter[itemKey] = element;
      }
    }
    this.busy = this.userService.list2(filter).subscribe({
      next: (data) => {
        this.list = data.payload;
        this.cols = data.admin_info.list_cols ?? data.admin_info.cols;
        for (const col of this.cols) {
          this.searchForm.addControl('ctrl_' + col, new FormControl('', Validators.required))
        }
      },
      error: (err) => console.log('ERRORE: ' + JSON.stringify(err.error.description)),
    });
  }

  deleteItem(id: number) {
    if (+id > 0) {
      this.busy = this.userService.delete(id).subscribe({
        next: () => this.getList(),
        error: (err) => console.log(err),
      });
    }
  }

  navigateTo(id: any) {
    this.router.navigate([`/user/${id}`]);
  }

  dialogConfirm(id) {
    console.log(id);
    if (+id > 0) {
      this.deleteItem(id);
    }
  }

  ngOnInit(): void {
    this.getList();
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   const provaAlert: Alert = new Alert();
    //   provaAlert.msg = 'cacchio';
    //   provaAlert.timeout = 2000;
    //   provaAlert.type = 'success';
    //   this.alertbox1.alerts = [];
    //   this.add(provaAlert);
    // }, 500);
    // setTimeout(() => {
    //   const provaAlert: Alert = new Alert();
    //   provaAlert.msg = 'cacchio';
    //   provaAlert.timeout = 2000;
    //   provaAlert.type = 'warning';
    //   this.alertbox1.alerts = [];
    //   this.add(provaAlert);
    // }, 2600);
    // setTimeout(() => {
    //   const provaAlert: Alert = new Alert();
    //   provaAlert.msg = 'cacchio';
    //   provaAlert.timeout = 2000;
    //   provaAlert.type = 'danger';
    //   this.alertbox1.alerts = [];
    //   this.add(provaAlert);
    // }, 5000);

  }
}
