
<div class="row d-flex justify-content-between">
    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
    </div>
    <div class="input-group input-group-sm mb-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <i class="fas fa-save"></i>
                Salva
            </button>
        </div>
    </div>
</div>

<!-- {{single|json}} -->
<div class="pr-3 pl-3 mb-5">
    
    <div class="table-responsive">
        <table class="table table-hover table-sm table-edit" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th scope="col" class="col-3">Descrizione</th>
                    <th scope="col" class="col-9">Valore</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let c of cols ">
                    <td>
                        {{c}}
                        <!-- <small>{{getControlType(c)}}</small>  -->
                    </td>
                    <td [ngSwitch]="getControlType(c)">
                        <input *ngSwitchCase="'Date'" type="date" class="form-control form-control-sm"
                            [ngModel]="single[c] | date:'yyyy-MM-dd'" (ngModelChange)="single[c] = $event" [name]="c">
                        <input *ngSwitchCase="'checkbox'" class="form-check-input" type="checkbox"
                            [(ngModel)]='single[c]' [id]="c" style="display: block; margin-left: 6px;">
                        <input *ngSwitchCase="'number'" class="form-control form-control-sm" type="number" [(ngModel)]='single[c]'
                            [id]="c" [disabled]="c=='id'">
                        <input *ngSwitchCase="'boolean'" class="form-check-input" type="checkbox"
                            [(ngModel)]='single[c]' [id]="c" style="display: block; margin-left: 6px;">
                        <input *ngSwitchDefault [type]="getControlType(c)" class="form-control form-control-sm"
                            [(ngModel)]='single[c]' [disabled]="c=='id'">
                    </td>
                </tr>

                <tr *ngFor="let r of rels ">
                    <!-- <td>
                    <div class="form-check"><input type="checkbox" class="form-check-input"></div>
                    </td> -->
                    <td>{{r.class_name | uppercase}}</td>
                    <td>
                        <!-- <select id="input_{{r.class_name}}" class="form-control"  [(ngModel)]="single[r.class_name + '_id']">
                            <option value='-1'>... </option>
                            <option *ngFor="let opt of getRelated(r.class_name).list "  [ngValue]="opt.id" [attr.selected]="opt.id == single[r.class_name + '_id']? 'checked' : null">
                                {{opt.description}}</option>
                        </select> -->

                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-between">
        <div class="input-group input-group-sm  mt-2 item d-flex justify-content-start">
        </div>
        <div class="input-group input-group-sm mt-2 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)='saveItem()'>
                    <i class="fas fa-save"></i>
                    Salva
                </button>
            </div>
        </div>
    </div>

</div>

<app-alertbox #alertbox1 [alerts]=""></app-alertbox>