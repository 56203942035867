import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MainComponent } from './pages/main/main.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserSingleComponent } from './pages/user-single/user-single.component';
import { AuthorizeGuard } from './guard/authorize.guard';
import { RealplayerListComponent } from './pages/realplayer-list/realplayer-list.component';
import { RealplayerSingleComponent } from './pages/realplayer-single/realplayer-single.component';
import { RealclubListComponent } from './pages/realclub-list/realclub-list.component';
import { RealclubSingleComponent } from './pages/realclub-single/realclub-single.component';
import { RealmatchListComponent } from './pages/realmatch-list/realmatch-list.component';
import { RealmatchSingleComponent } from './pages/realmatch-single/realmatch-single.component';
import { RealcompetitionListComponent } from './pages/realcompetition-list/realcompetitione-list.component';
import { RealcompetitionSingleComponent } from './pages/realcompetition-single/realcompetition-single.component';
import { RealmatchTableComponent } from './pages/realmatch-table/realmatch-table.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizeGuard] },
  { path: 'main', component: MainComponent, canActivate: [AuthorizeGuard] },
  { path: 'user/list', component: UserListComponent, canActivate: [AuthorizeGuard] },
  { path: 'user/:id', component: UserSingleComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_player/list', component: RealplayerListComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_player/:id', component: RealplayerSingleComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_club/list', component: RealclubListComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_club/:id', component: RealclubSingleComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_competition/list', component: RealcompetitionListComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_competition/:id', component: RealcompetitionSingleComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_match/list', component: RealmatchListComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_match/:id/table', component: RealmatchTableComponent, canActivate: [AuthorizeGuard] },
  { path: 'real_match/:id', component: RealmatchSingleComponent, canActivate: [AuthorizeGuard] },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
