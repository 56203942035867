import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  @Output()
  userEvent: EventEmitter<any> = new EventEmitter();
  communicate: EventEmitter<any> = new EventEmitter();
  // dateChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  getSessionId(): string {
    // return '302396f4-c4d9-4cd8-aa96-405977c35031';
    return localStorage.getItem('sid');
  }

  setSessionId(s: string): void {
    // this.sessionId = _s;
    localStorage.setItem('sid', s);
  }

  displayMessage(msg: string, type: string) {
    this.communicate.emit({ event, m: msg, t: type });
  }

  userChange(u: string, d: Date) {
    this.userEvent.emit({ event, username: u, dateIn: d });
  }

}
