
<div class="row d-flex justify-content-between">
    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
    </div>
    <div class="input-group input-group-sm mb-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <i class="fas fa-save"></i>
                Salva
            </button>
        </div>
    </div>
</div>

<!-- {{single|json}} -->
<div class="pr-3 pl-3 mb-5">
    
    <div class="table-responsive">
        <table class="table table-hover table-sm table-edit" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th scope="col" class="col-3">Descrizione</th>
                    <th scope="col" class="col-9">Valore</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        ID
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['id']" id="id" disabled>
                    </td>
                </tr>
                <tr>
                    <td>
                        Descrizione
                    </td>
                    <td>
                        <input class="form-control form-control-sm"  [(ngModel)]="single['description']" id="description" >
                    </td>
                </tr>
                <tr>
                    <td>
                        Data inizio stagione
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="date" [(ngModel)]="single['currentSeason_startDate']" id="currentSeason_startDate" >
                    </td>
                </tr>
                <tr>
                    <td>
                        Data fine stagione
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="date" [(ngModel)]="single['currentSeason_endDate']" id="currentSeason_endDate" >
                    </td>
                </tr>


                <tr *ngFor="let r of rels ">
                    <!-- <td>
                    <div class="form-check"><input type="checkbox" class="form-check-input"></div>
                    </td> -->
                    <td>{{r.class_name | uppercase}}</td>
                    <td>
                        <!-- <select id="input_{{r.class_name}}" class="form-control"  [(ngModel)]="single[r.class_name + '_id']">
                            <option value='-1'>... </option>
                            <option *ngFor="let opt of getRelated(r.class_name).list "  [ngValue]="opt.id" [attr.selected]="opt.id == single[r.class_name + '_id']? 'checked' : null">
                                {{opt.description}}</option>
                        </select> -->

                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-between">
        <div class="input-group input-group-sm  mt-2 item d-flex justify-content-start">
        </div>
        <div class="input-group input-group-sm mt-2 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)='saveItem()'>
                    <i class="fas fa-save"></i>
                    Salva
                </button>
            </div>
        </div>
    </div>

</div>

<app-alertbox #alertbox1 [alerts]=""></app-alertbox>