import { Component, Input, OnInit, Output } from '@angular/core';
import { Alert } from 'src/app/classes/alert';

@Component({
  selector: 'app-alertbox',
  templateUrl: './alertbox.component.html',
  styleUrls: ['./alertbox.component.scss'],
})
export class AlertboxComponent implements OnInit {
//   alerts: Alert[] = [];

  @Input() alerts: Alert[] = [];
  
  constructor() {}

  onClosed(dismissedAlert: Alert): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  ngOnInit(): void {}
}
