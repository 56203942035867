<div>
    <div class="table-responsive">
        <pagination *ngIf="list" [totalItems]="paginationItems.total" [itemsPerPage]="paginationItems.per_page"
            [(ngModel)]="paginationItems.current_page" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
            nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="rotate" [maxSize]="maxSize"
            class="d-flex justify-content-center"></pagination>
        <!-- <span *ngIf="returnedArray">
            {{returnedArray}}
        </span> -->
        <table class="table table-responsive table-striped table-hover table-list" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <!--<th *ngFor="let c of cols "><div class="text-truncate">{{c}}</div></th>-->
                    <!-- <td *ngFor="let r of list?.rels ">{{r}}</td> -->
                    <th>
                        <div class="truncate"><span class="truncated">id</span></div>
                    </th>
                    <th>
                        <div class="truncate"><span class="truncated">calciatore</span></div>
                    </th>
                    <th>
                        <div class="truncate"><span class="truncated">club</span></div>
                    </th>
                    <th>
                        <div class="truncate"><span class="truncated">ruolo</span></div>
                    </th>
                    <th>
                        <div class="truncate"><span class="truncated">ruoli specifici</span></div>
                    </th>
                    <th>
                        <div class="truncate"><span class="truncated">vdm</span></div>
                    </th>

                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of returnedArray">
                    <td>{{item['id']}}</td>
                    <td>{{item['calciatore']}}</td>
                    <td> {{item['realclub'] ? (item['realclub']['common_mame'] ? item['realclub']['common_mame'] : item['realclub']['description']) : ''}}
                    </td>
                    <td>
                        <span class="shirt-number" class="{{item['ruolo']}}">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="30" viewBox="0 0 640 512">
                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path opacity="1" fill="currentColor" d="M211.8 0c7.8 0 14.3 5.7 16.7 13.2C240.8 51.9 277.1 80 320 80s79.2-28.1 91.5-66.8C413.9 5.7 420.4 0 428.2 0h12.6c22.5 0 44.2 7.9 61.5 22.3L628.5 127.4c6.6 5.5 10.7 13.5 11.4 22.1s-2.1 17.1-7.8 23.6l-56 64c-11.4 13.1-31.2 14.6-44.6 3.5L480 197.7V448c0 35.3-28.7 64-64 64H224c-35.3 0-64-28.7-64-64V197.7l-51.5 42.9c-13.3 11.1-33.1 9.6-44.6-3.5l-56-64c-5.7-6.5-8.5-15-7.8-23.6s4.8-16.6 11.4-22.1L137.7 22.3C155 7.9 176.7 0 199.2 0h12.6z"/>
                            </svg>
                            <span class="number">{{item['ruolo']}}</span>
                        </span>
                        
                    </td>
                    <td>
                        <ng-container  *ngFor="let c of ruoliSplittati(item.ruoli_specifici); let i = index" >
                            <ng-container *ngIf="ruoliSplittati(item.ruoli_specifici)[i] != ''">
                                <div class="badge-generic-role {{ruoliSplittati(item.ruoli_specifici)[i]}}" >
                                    <span>{{ruoliSplittati(item.ruoli_specifici)[i]}}</span>
                                </div>
                            </ng-container>
                        </ng-container >
                    </td>
                    <td>{{item['vdm_pmc']}}</td>
                    
                    <td class="actions">
                        <button class="btn btn-app btn-sm btn-edit" type="button" (click)="navigateTo(item?.id)">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </button>
                        <button class="btn btn-app btn-sm btn-trash" type="button" (click)="one.openModal(item?.id)">
                            <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination *ngIf="list" [totalItems]="paginationItems.total" [itemsPerPage]="paginationItems.per_page"
            [(ngModel)]="paginationItems.current_page" (pageChanged)="pageChanged($event)" previousText="&lsaquo;"
            nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="rotate" [maxSize]="maxSize"
            class="d-flex justify-content-center"></pagination>
    </div>
</div>
<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)"></app-confirmation-dialog>
<app-alertbox #alertbox1 [alerts]=""></app-alertbox>