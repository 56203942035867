import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpDataService } from 'src/app/services/http-data.service';
import { User, UserResponse } from 'src/app/classes/user';
import { Subscription } from 'rxjs/internal/Subscription';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { Alert } from 'src/app/classes/alert';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-user-single',
  templateUrl: './user-single.component.html',
  styleUrls: ['./user-single.component.scss'],
})
export class UserSingleComponent implements OnInit {
  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  id: number;
  single: User;
  cols: any;
  rels: Array<any>;
  faPlus = faSave;

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;

  constructor(private ar: ActivatedRoute, private httpdata: HttpDataService, public userService: UserService, private router: Router) {
    this.pathList = [['Utenti', 'user/list']];
  }

  loga($event) {
    this.pathList.push($event);
  }

  ngOnInit() {
    this.rels = [];
    this.id = parseInt(this.ar.snapshot.paramMap.get('id'), 10);
    this.single = new User();
    // this.getSingle(this.id);
  }

  getControlType(control_name: string) : string {
    if (control_name in this.single.controls) {
      return this.single.controls[control_name];
    }
    return '';
  }

  // getSingle(id: number) {
  //   const isNew = id == 0 ? true : false;
  //   if (id == 0) id = 1;
  //   this.busy = this.userService.get(id).subscribe(
  //     (data: UserResponse) => {
  //       if (isNew) {
  //         this.single = new User();
  //       } else {
  //         this.single = new User();
  //         for (const key in data.payload) {
  //           if (Object.prototype.hasOwnProperty.call(this.single, key)) {
  //             this.single[key] = data.payload[key];
  //           }
  //         }
  //         // this.single = data.payload;
  //       }

  //       this.cols = data.admin_info.cols.filter((c) => c.indexOf('.') == -1); // rimuovo i campi fk
  //       this.pathList.push([this.single.indirizzo_email, 'user/' + this.id]);
  //       // for (const iterator of data.admin_info.rels) {
  //       //   this.getRels(iterator);
  //       // }
  //     },
  //     (err) => {
  //       // console.error(err);
  //       alert('ERRORE: ' + JSON.stringify(err.error.description));
  //     }
  //   );
  // }

  getRels(rel: string) {
    this.httpdata.get('/' + rel).subscribe(
      (data) => {
        this.rels.push({ class_name: rel, list: data });
      },
      (err) => {
        console.error(err);
        alert('ERRORE: ' + JSON.stringify(err.error.description));
      }
    );
  }

  getRelated(rel: string) {
    return this.rels.find((r) => {
      return r.class_name == rel;
    }).list;
  }

  saveItem() {
    const payload = User.cleanup(this.single);

    if (this.id == 0) {
      this.busy = this.userService.create(payload).subscribe({
        next: () => this.router.navigate([`/user/list`]),
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    } else {
      this.busy = this.userService.update(this.id, payload).subscribe({
        next: () => this.router.navigate([`/user/${this.id}`]),
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    }
  }

}


