<div>
    <div class="table-responsive">
        <pagination *ngIf="list" [totalItems]="list.length" [itemsPerPage]="50" (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="rotate"
            [maxSize]="maxSize" class="d-flex justify-content-center"></pagination>

        <table class="table table-responsive table-striped table-hover table-list" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th>
                        id
                    </th>
                    <th>
                        <div class="truncate">
                            Description
                        </div>
                    </th>
                    <th>
                        <div class="truncate">
                            Data inizio
                        </div>
                    </th>
                    <th>
                        <div class="truncate">
                            Data fine
                        </div>
                    </th>
                    <th>Azioni</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of returnedArray">
                    <td>{{item.id}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.currentSeason_startDate| date:'dd-MM-yyyy ' }}</td>
                    <td>{{item.currentSeason_endDate| date:'dd-MM-yyyy ' }}</td>
                    <td class="actions">
                        <button class="btn btn-app btn-sm btn-edit" type="button" (click)="navigateTo(item?.id)">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </button>
                        <button class="btn btn-app btn-sm btn-trash" type="button" (click)="one.openModal(item?.id)"
                            disabled>
                            <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination *ngIf="list" [totalItems]="list.length" [itemsPerPage]="50" (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" [rotate]="rotate"
            [maxSize]="maxSize" class="d-flex justify-content-center"></pagination>
    </div>
</div>
<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)"></app-confirmation-dialog>
<app-alertbox #alertbox1 [alerts]=""></app-alertbox>