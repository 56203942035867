import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpDataService } from './services/http-data.service';
import { GlobalService } from './services/global.service';

import { NgBusyModule } from 'ng-busy';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
// import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { AppComponent } from './app.component';

import { BreadcrumbComponent } from './general/breadcrumb/breadcrumb.component';
import { MenuComponent } from './general/menu/menu.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { MainComponent } from './pages/main/main.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserSingleComponent } from './pages/user-single/user-single.component';
import { ConfirmationDialogComponent } from './general/confirmation-dialog/confirmation-dialog.component';
import { AlertboxComponent } from './general/alertbox/alertbox.component';
import { RealplayerListComponent } from './pages/realplayer-list/realplayer-list.component';
import { RealplayerSingleComponent } from './pages/realplayer-single/realplayer-single.component';
import { ListComponent } from './general/list/list.component';
import { ListRealclubComponent } from './general/list-realclub/list-realclub.component';
import { ListRealmatchComponent } from './general/list-realmatch/list-realmatch.component';
import { ListRealPlayerComponent } from './general/list-realplayer/list-realplayer.component';
import { ListRealCompetitionComponent } from './general/list-realcompetition/list-realcompetition.component';
import { SingleComponent } from './general/single/single.component';
import { SingleClubComponent } from './general/single-club/single-club.component';
import { SingleUserComponent } from './general/single-user/single-user.component';
import { SingleCompetitionComponent } from './general/single-competition/single-competition.component';
import { SinglePlayerComponent } from './general/single-player/single-player.component';
import { RealclubListComponent } from './pages/realclub-list/realclub-list.component';
import { RealclubSingleComponent } from './pages/realclub-single/realclub-single.component';
import { RealmatchListComponent } from './pages/realmatch-list/realmatch-list.component';
import { RealmatchSingleComponent } from './pages/realmatch-single/realmatch-single.component';
import { RealcompetitionListComponent } from './pages/realcompetition-list/realcompetitione-list.component';
import { RealcompetitionSingleComponent } from './pages/realcompetition-single/realcompetition-single.component';
import { SingleRealmatchComponent } from './general/single-realmatch/single-realmatch.component';
import { ListPagedComponent } from './general/list-paged/list-paged.component';
import { RealmatchTableComponent } from './pages/realmatch-table/realmatch-table.component';
import { SingleRealmatchtableComponent } from './general/single-realmatchtable/single-realmatchtable.component';
@NgModule({
  declarations: [
    AppComponent,
    BreadcrumbComponent,
    MenuComponent,
    LoginComponent,
    DashboardComponent,
    PageNotFoundComponent,
    MainComponent,
    UserListComponent,
    UserSingleComponent,
    ConfirmationDialogComponent,
    AlertboxComponent,
    RealplayerListComponent,
    RealplayerSingleComponent,
    ListComponent,
    ListRealclubComponent,
    ListRealmatchComponent,
    ListRealPlayerComponent,
    ListRealCompetitionComponent,
    ListPagedComponent,
    SingleComponent,
    SingleUserComponent,
    SingleClubComponent,
    SingleCompetitionComponent,
    SinglePlayerComponent,
    RealclubListComponent,
    RealclubSingleComponent,
    RealmatchListComponent,
    RealcompetitionListComponent,
    RealcompetitionSingleComponent,
    RealmatchSingleComponent,
    SingleRealmatchComponent,
    SingleRealmatchtableComponent,
    RealmatchTableComponent,
    RealmatchTableComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgBusyModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    AccordionModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot()
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [HttpDataService, GlobalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
