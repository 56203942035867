import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Subscription, forkJoin } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { BaseClass } from 'src/app/classes/base_class';
import { RealClub, RealClubResponse } from 'src/app/classes/real_club';
import { RealPlayer, RealPlayerResponse } from 'src/app/classes/real_player';
import { User, UserResponse } from 'src/app/classes/user';
import { AbstractService } from 'src/app/services/abstract.service';
import { AlertboxComponent } from '../alertbox/alertbox.component';
import { Booking, Goal, RealMatch, RealMatchResponse, Score, Substitution } from 'src/app/classes/real_match';
import { RealClubService } from 'src/app/services/real-club.service';
import { RealScardSummary } from 'src/app/classes/realscardsummary';
import { RealScardsummaryService } from 'src/app/services/real-scardsummary.service';
import { RealMatchService } from 'src/app/services/real-match.service';

@Component({
  selector: 'app-single-realmatchtable',
  templateUrl: './single-realmatchtable.component.html',
  styleUrls: ['./single-realmatchtable.component.scss']
})
export class SingleRealmatchtableComponent implements OnInit {
  busy: Subscription;
  faPlus = faSave;
  rels: Array<any>;
  singleResponse: UserResponse | RealClubResponse | RealPlayerResponse;
  // cols: any;
  varCustomClass = 'accordionCustomStyle';

  @Input() id: number;
  @Input() single: RealMatch;
  @Input() componentService: AbstractService;
  @Input() routePart: string;
  @Output() pathListEvent = new EventEmitter<any[]>();
  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;
  rplayer1: RealPlayer[];
  rplayer2: RealPlayer[];
  rscard: RealScardSummary[];


  homeClubScardList1: RealScardSummary[] = [];
  homeClubScardList1a: RealScardSummary[] = [];
  homeClubScardList2: RealScardSummary[] = [];
  homeClubScardList2a: RealScardSummary[] = [];

  homeClub = "";
  visitorClub = "";

  constructor(private router: Router, public realclubService: RealClubService, public realScardsummaryServive: RealScardsummaryService, realMatchService: RealMatchService) { }

  ngOnInit(): void {
    this.getSingle(this.id);
  }

  lineUp(item): RealScardSummary {
    let scard: RealScardSummary = new RealScardSummary();
    // scard.id = item.id;
    scard.realmatch_id = this.id;
    scard.real_competition_id = this.single.real_competition_id;
    scard.matchday = this.single.matchday;

    scard.realplayer_id = item.id;
    scard.realplayer_description = item.calciatore;
    scard.realplayer_titolarita_potenziale = item.titolarita_potenziale;
    scard.ruolo = item.ruolo;
    scard.role_position = 0;
    scard.shirt_number = item.shirtNumber;
    const rp_in_scard = this.rscard?.find(rscard_item => {
      return item.id == rscard_item.realplayer_id;
    });
    if (rp_in_scard) {
      scard.id = rp_in_scard.id;
      scard.fonte_voti = rp_in_scard.fonte_voti / 10;
      scard.voto = rp_in_scard.voto / 10;
      scard.titolare = rp_in_scard.titolare;
      scard.realplayer_titolarita_potenziale = rp_in_scard.titolare;
      scard.da_panchina = rp_in_scard.da_panchina;
      scard.minuti_giocati = rp_in_scard.minuti_giocati;
      scard.rigore_parato = rp_in_scard.rigore_parato;
      scard.rigore_sbagliato = rp_in_scard.rigore_sbagliato;
      scard.gol_fatti = rp_in_scard.gol_fatti;
      scard.assist = rp_in_scard.assist;
      scard.assist_calcio_piazzato = rp_in_scard.assist_calcio_piazzato;
      scard.gol_subiti = rp_in_scard.gol_subiti;
      scard.autogol = rp_in_scard.autogol;
      scard.ammonizioni = rp_in_scard.ammonizioni;
      scard.espulsione = rp_in_scard.espulsione;
      scard.gol_decisivi_vittoria = rp_in_scard.gol_decisivi_vittoria;
      scard.gol_decisivo_pareggio = rp_in_scard.gol_decisivo_pareggio;
      scard.clean_shit = rp_in_scard.clean_shit;
    } else {
      scard.fonte_voti = 0;
      scard.voto = 0;
      scard.titolare = 0;
      scard.da_panchina = 0;
      scard.minuti_giocati = 0;
      scard.rigore_parato = 0;
      scard.rigore_sbagliato = 0;
      scard.gol_fatti = 0;
      scard.assist = 0;
      scard.assist_calcio_piazzato = 0;
      scard.gol_subiti = 0;
      scard.autogol = 0;
      scard.ammonizioni = 0;
      scard.espulsione = 0;
      scard.gol_decisivi_vittoria = 0;
      scard.gol_decisivo_pareggio = 0;
      scard.clean_shit = 0;
    }
    return scard;
  }

  getSingle(id: number) {
    const isNew = id == 0 ? true : false;
    if (id == 0) id = 1;
    // RealMatch
    this.busy = this.componentService.get(id).subscribe(
      (data: RealMatchResponse) => {
        if (!isNew) {
          this.single = data.payload;
        }


        /**
         * DONE: ANDRANNO LETTI ANCHE I DATI IN API CORRISPONDENZA DELLA TABELLA 'realscardsummaries'
         *    SI DOVRA' FARE IN MODO CHE SIANO MOSTRATI TUTTI I PLAYER IN LISTA SIA NEL CAMPO JSON DELLA TABELLA DEL REAL MATCH 
         *    CHE I PLAYER DENLLA realscardsummaries 
         */

        const $rclub1 = this.realclubService.get(this.single['realclub1_id']);
        const $rclub2 = this.realclubService.get(this.single['realclub2_id']);
        const $rscard = this.realScardsummaryServive.get(this.id);
        let filter: Array<any> | null = null;
        filter = [];

        this.busy = forkJoin([$rclub1, $rclub2, $rscard]).subscribe(([rclub1, rclub2, rscard]) => {
          this.pathListEvent.emit(
            [['Real Matches', 'real_match/list'],
            [rclub1.payload.description + ' - ' + rclub2.payload.description, `${this.routePart}/${this.single.id}`],
            ['Tabellino', "real_match/" + this.id]]
          );
          // this.pathListEvent.emit(
          //   [rclub1.payload.description + ' - ' + rclub2.payload.description, `${this.routePart}/${this.single.id}`]
          // );
          // this.pathListEvent.emit(
          //   ['Tabellino', "real_match/" + this.id]
          // );

          this.homeClub = rclub1.payload.description;
          this.visitorClub = rclub2.payload.description;
          this.rscard = rscard.payload;
          // console.log(rscard);
          this.homeClubScardList1 = [];
          this.homeClubScardList1a = [];
          this.homeClubScardList2 = [];
          this.homeClubScardList1a = [];
          if (this.single.realclub1_lineup) {
            for (let item of this.single.realclub1_lineup) {
              this.homeClubScardList1.push(this.lineUp(item));
            }
          }
          if (this.single.realclub1_bench) {
            for (let item of this.single.realclub1_bench) {
              this.homeClubScardList1a.push(this.lineUp(item));
            }
          }
          if (this.single.realclub2_lineup) {
            for (let item of this.single.realclub2_lineup) {
              this.homeClubScardList2.push(this.lineUp(item));
            }
          }
          if (this.single.realclub2_bench) {
            for (let item of this.single.realclub2_bench) {
              this.homeClubScardList2a.push(this.lineUp(item));
            }
          }
        });
      },
      (err) => {
        console.log('ERRORE: ' + JSON.stringify(err.error.description));
      }
    );


  }

  saveItem() {
    //  spread syntax ( ... ) per espandere il contenuto degli array
    let payload1: RealScardSummary[] = [...this.homeClubScardList1, ...this.homeClubScardList1a, ...this.homeClubScardList2, ...this.homeClubScardList2a];
    let payload: RealScardSummary[] = [];
    for (let x of payload1) {
      const y = Object.assign({}, x)
      y.voto = y.voto * 10;
      y.fonte_voti = y.fonte_voti * 10;
      delete y['shirt_number'];

      // y.titolare = y.realplayer_titolarita_potenziale
      // delete y['controls']
      payload.push(y);
    }

    if (this.single.id == 0) {
      this.busy = this.realScardsummaryServive.create(payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'normal';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
          this.router.navigate([`/${this.routePart}/list`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg = '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    } else {
      let score: Score = new Score();
      score.homeTeam = '0';
      score.awayTeam = '0';
      let homeTeam: RealScardSummary[] = [...this.homeClubScardList1, ...this.homeClubScardList1a];
      let awayTeam: RealScardSummary[] = [...this.homeClubScardList2, ...this.homeClubScardList2a];
      for (const playerSCard of homeTeam) {
        if (playerSCard.gol_fatti > 0) {
          score.homeTeam = (Number(score.homeTeam) + playerSCard.gol_fatti).toString();
        }

        if (playerSCard.autogol > 0) {
          score.awayTeam = (Number(score.awayTeam) + playerSCard.autogol).toString();
        }
      }

      for (const playerSCard of awayTeam) {
        if (playerSCard.gol_fatti > 0) {
          score.awayTeam = (Number(score.awayTeam) + playerSCard.gol_fatti).toString();
        }
        if (playerSCard.autogol > 0) {
          score.homeTeam = (Number(score.homeTeam) + playerSCard.autogol).toString();
        }
      }
      this.single.score_fullTime = score;
      this.busy = this.realScardsummaryServive.update(this.single.id, payload).subscribe({
        next: () => {
          const provaAlert: Alert = new Alert();
          provaAlert.msg = "Salvataggio riuscito";
          provaAlert.timeout = 5000;
          provaAlert.type = 'primary';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert);
          this.busy = this.componentService.update(this.single.id, this.single).subscribe({
            next: () => { }
          });
          // this.getSingle(this.id);

          // this.router.navigate([`/${this.routePart}/${this.single.id}`]);
        },
        error: (err: HttpErrorResponse) => {
          let msg =   '';
          if ('errors' in err.error) {
            msg = JSON.stringify(err.error.errors)
          } else {
            msg = err.error.status + ' - ' + err.error.payload;
          }
          const provaAlert: Alert = new Alert();
          provaAlert.msg = msg;
          provaAlert.timeout = 5000;
          provaAlert.type = 'danger';
          this.alertbox1.alerts = [];
          this.alertbox1.alerts.push(provaAlert)
        },
      });
    }
  }

  parse(o) {
    return JSON.parse(o);
  }

  getControlType(control_name: string): string {
    if (control_name in this.single.controls) {
      return this.single.controls[control_name];
    }
    return '';
  }

}
