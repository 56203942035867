import { Component, TemplateRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  modalRef: BsModalRef;
  message: string;
  idForDelete: number;
  @Output()
  dialogOutput: EventEmitter<Number> = new EventEmitter<Number>();

  @ViewChild('templateref') public templateref: TemplateRef<any>;
  constructor(private modalService: BsModalService) {}

  openModal(id: number) {
    this.idForDelete = id;
    this.modalRef = this.modalService.show(this.templateref, { class: 'modal-sm' });
  }

  confirm(): void {
    this.message = 'Confirmed!';
    this.modalRef.hide();
    this.dialogOutput.emit(this.idForDelete);
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
    this.dialogOutput.emit(-1);
  }
}

export class ModelComponentData {
  action: string;
  id: number;
}
