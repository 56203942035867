import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../classes/user';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(http: HttpClient, public router: Router) {
    super(http);
    this.entity = 'user-admin';
    if (localStorage.getItem(window.btoa('currentUser'))) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(window.atob(localStorage.getItem(window.btoa('currentUser')))));
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(null);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getCurrentUser(): User {
    const ua = localStorage.getItem(window.btoa('currentUser'));
    if (!ua) {
      return null;
    }
    const payload = window.atob(ua);
    return JSON.parse(payload);
  }

  login(username: string, password: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const body = `{ "indirizzo_email": "${username}", "password": "${password}" }`;
    return this.http.post<any>(`${this.api}/auth/login`, body, options).pipe(
      map((response) => {
        let user_r: User = response.user_loggedin;
        let user: User = new User();
        // store user details and basic auth credentials in local
        // storage to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ':' + password);
        user.indirizzo_email = response.user_loggedin.indirizzo_email;
        user.session_token = response.access_token;
        const payload = window.btoa(JSON.stringify(user));
        localStorage.setItem(window.btoa('currentUser'), payload);
        // localStorage.setItem(window.btoa('isLoggedin'), 'true');
        // this.global.setLanguage('it');
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    // const token = this.getCurrentUser().session.token;
    return this.http.get<any>(`${this.api}/logout`, options).pipe(
      map((entity) => {
        localStorage.removeItem(window.btoa('currentUser'));
        //   const structureId = this.structureService.getId();
        this.router.navigate([`/`]);
        return entity;
      }),
      catchError((err) => {
        localStorage.removeItem(window.btoa('currentUser'));
        return err;
      })
    );
  }

  updateUser(user: User): Observable<User> {
    // console.log(`${this.userUrl}`);
    // const currentUser: User = this.getCurrentUser();
    // user.id = user.userId;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.patch<any>(`${this.api}/user`, user, options);
  }

  registerUser(user: User): Observable<User> {
    // console.log(`${this.userUrl}`);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.patch<any>(`${this.api}/user`, user, options);
  }
}
