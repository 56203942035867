import { adminInfo } from './adminInfo';
import { BaseClass } from './base_class';
import { BaseInterface } from './base_interface';
import { RealCompetition } from './real_competition';

export class RealClub extends BaseClass implements BaseInterface {

  constructor() {
    super();
    this.id = 0;
    this.description = "";
    this.crestUrl = "";
    this.common_mame = "";
    this.position = 0;
    this.playedGames = 0;
    this.won = 0;
    this.draw = 0;
    this.lost = 0;
    this.points = 0;
    this.goalsFor = 0;
    this.goalsAgainst = 0;
    this.stage = "";
    this.real_competition_id = 0;
    this.created_at = null;
    this.updated_at = null;
    this.deleted_at = null;
  }

  controls: any = {
    id: "number",
    description: "string",
    crestUrl: "string",
    common_mame: "string",
    created_at: "Date",
    updated_at: "Date",
    deleted_at: "Date",
  }

  id: number;
  description: string;
  common_mame: string;
  crestUrl: string;
  position: number;
  playedGames: number;
  won: number;
  draw: number;
  lost: number;
  points: number;
  goalsFor: number;
  goalsAgainst: number;
  stage: string;
  real_competition_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  real_competition: RealCompetition;

}

export class RealClubResponse {
  payload: RealClub;
  admin_info: adminInfo;
}
