import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { FilterRealClub } from '../classes/filterRealClub';

@Injectable({
  providedIn: 'root'
})
export class RealClubService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
    this.entity = 'realclub';
  }

  private filterObs$: BehaviorSubject<FilterRealClub> = new BehaviorSubject(new FilterRealClub());

  getFilterObs(): Observable<FilterRealClub> {
      return this.filterObs$.asObservable();
  }

  setFilterObs(filter: FilterRealClub) {
      this.filterObs$.next(filter);
  }
}
