
export class BaseClass {
  static controls: any = {};

  static cleanup(item) {
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const t = item.controls[key];
        // console.log(t, key, item[key]);
        if (t == 'string') {
          item[key] = item[key] ?? '';
        } else if (t == 'number') {
          item[key] = +item[key] ?? 0;
        } else if (t == 'boolean') {
          item[key] = !!item[key] ?? false;
        } else if (t == 'any' || t == 'undefined') {
          delete item[key];
        } else if (t === 'Date') {
          if (item[key] == null) {
            delete item[key];
          }
        }
      }
    }
    return item;
  }
}