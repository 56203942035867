import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription, forkJoin } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { FilterRealPlayer } from 'src/app/classes/filterRealPlayer';
import { Pagination } from 'src/app/classes/pagination';
import { RealClub } from 'src/app/classes/real_club';
import { RealPlayer } from 'src/app/classes/real_player';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { RealClubService } from 'src/app/services/real-club.service';
import { RealPlayerService } from 'src/app/services/real-player.service';

@Component({
  selector: 'app-realplayer-list',
  templateUrl: './realplayer-list.component.html',
  styleUrls: ['./realplayer-list.component.scss']
})
export class RealplayerListComponent implements OnInit {
  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  list: RealPlayer[];
  listPagination: Pagination;
  realClub_list: RealClub[];
  faPlus = faPlusCircle;
  faTrash = faTrashAlt;
  cols: string[];
  cols_list: string[];
  searchForm = this.fb.group({});

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;
  filterRealPlayer: FilterRealPlayer;
  unsubscribe$: Subscription;
  maxSize = 15;


  constructor(private router: Router, public realPlayerService: RealPlayerService, public realClubService: RealClubService, private fb: FormBuilder) {
    this.pathList = [['Real Players', 'real_player/list']];
    this.realClub_list = [];
  }

  formControlsNames(form) {
    return Object.keys(form);
  }

  add(a: Alert) {
    this.alertbox1.alerts.push(a);
  }

  page_nr($event) {
    this.searchForm.controls['ctrl_page'].setValue($event);
    this.getList();
  }

  getList() {
    let filter: Array<any> | null = null;
    if (this.searchForm.value) {
      filter = [];
    }
    this.filterRealPlayer = new FilterRealPlayer();
    for (const key in this.searchForm.value) {
      if (Object.prototype.hasOwnProperty.call(this.searchForm.value, key)) {
        const element = this.searchForm.value[key];
        if ((element || element == '') && element != 'null') {
          const itemKey = key.split('_')
          itemKey.shift();
          filter[itemKey.join('_')] = element;
          this.filterRealPlayer['ctrl_' + itemKey.join('_')] = element;
        }
      }
    }
    this.realPlayerService.setFilterObs(this.filterRealPlayer);

    filter['limit'] = this.maxSize;
    if (filter['realclub_id'] == '0') {
      delete filter['realclub_id'];
    }
    this.busy = this.realPlayerService.list2(filter).subscribe(realplayers => {
      this.list = realplayers.payload;
      this.listPagination = realplayers.pagination;
      for (const rplayer of this.list) {
        const rclub = this.realClub_list.find((obj) => {
          return obj.id === rplayer.realclub_id;
        });
        rplayer['realclub_description'] = rclub ? (rclub.common_mame ? rclub.common_mame : rclub.description) : '';
      }
    });


  }

  realClub_dd_select(rclub_id) {
    this.getList();
  }

  dialogConfirm(event) {
    console.log(event);
  }

  navigateTo(id: number) {
    this.router.navigate([`/real_player/${id}`]);
  }

  ngOnInit(): void {
    this.cols = ['realclub_id', 'id', 'calciatore', 'realclub_description', 'ruolo', 'nazionalita', 'data_nascita']; //data.admin_info.list_cols ?? data.admin_info.cols;
    this.cols_list = ['id', 'calciatore', 'realclub_description', 'ruolo', 'nazionalita', 'data_nascita']
    this.searchForm.addControl('ctrl_page', new FormControl('', Validators.required))
    for (const col of this.cols) {
      this.searchForm.addControl('ctrl_' + col, new FormControl('', Validators.required))
    }
    this.searchForm.controls['ctrl_realclub_id'].setValue(0);
    let filter: Array<any> = [];
    filter['limit'] = 30;
    filter['realcompetition_id'] = 6;
    const $rclubs = this.realClubService.list2(filter).subscribe(rclubs => {
      this.realClub_list = rclubs.payload;
      // this.realClub_list = rclubs.payload.sort( (a,b) => (a.common_mame < b.common_mame ? -1 : 1));
    });

    this.unsubscribe$ = this.realPlayerService.getFilterObs().subscribe(filter => {
      this.filterRealPlayer = filter;
      this.searchForm.controls['ctrl_page'].setValue(this.filterRealPlayer.ctrl_page);
      this.searchForm.controls['ctrl_id'].setValue(this.filterRealPlayer.ctrl_id);
      this.searchForm.controls['ctrl_realclub_id'].setValue(this.filterRealPlayer.ctrl_realclub_id);
      this.searchForm.controls['ctrl_calciatore'].setValue(this.filterRealPlayer.ctrl_calciatore);
      this.searchForm.controls['ctrl_nazionalita'].setValue(this.filterRealPlayer.ctrl_nazionalita);
      this.searchForm.controls['ctrl_ruolo'].setValue(this.filterRealPlayer.ctrl_ruolo);
      this.searchForm.controls['ctrl_data_nascita'].setValue(this.filterRealPlayer.ctrl_data_nascita);
    });
    this.getList();
  }
}
