<app-breadcrumb [pathList]="pathList"></app-breadcrumb>
<div [ngBusy]="{busy: busy, message: 'Loading...', backdrop: false, delay: 0, minDuration: 600}"></div>


<div class="grid-container">

  <div class="grid-row">

    <div class="grid-heading">
      <h2>Stagione 2023-2024</h2>
    </div>

    <div class="grid-item">
      <a class="wrapping-link" ria-expanded="false" [routerLink]="['../real_competition/list']"></a>
      <div class="grid-item-wrapper">
        <div class="grid-item-container">

          <div class="grid-content-centered">
            <i class="fas fa-futbol fa-fw"></i>
            Real Competitions
          </div>

        </div>
      </div>
    </div>

    <div class="grid-item">
      <a class="wrapping-link" ria-expanded="false" [routerLink]="['../real_club/list']"></a>
      <div class="grid-item-wrapper">
        <div class="grid-item-container">

          <div class="grid-content-centered">
            <i class="fas fa-shield-alt fa-fw"></i>
            Real Clubs
          </div>

        </div>
      </div>
    </div>

    <div class="grid-item">
      <a class="wrapping-link" ria-expanded="false" [routerLink]="['../real_player/list']"></a>
      <div class="grid-item-wrapper">
        <div class="grid-item-container">

          <div class="grid-content-centered">
            <i class="fas fa-user-shield fa-fw"></i>
            Real Players
          </div>

        </div>
      </div>
    </div>

    <div class="grid-item">
      <a class="wrapping-link" ria-expanded="false" [routerLink]="['../real_match/list']"></a>
      <div class="grid-item-wrapper">
        <div class="grid-item-container">

          <div class="grid-content-centered">
            <i class="fas fa-running fa-fw"></i>
            Real Matches
          </div>

        </div>
      </div>
    </div>

  </div>
</div>