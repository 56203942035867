import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RealCompetition } from 'src/app/classes/real_competition';
import { RealCompetitionService } from 'src/app/services/real-competition.service';

@Component({
  selector: 'app-realcompetition-single',
  templateUrl: './realcompetition-single.component.html',
  styleUrls: ['./realcompetition-single.component.scss']
})
export class RealcompetitionSingleComponent implements OnInit {
  pathList: string[][];
  single: RealCompetition;
  id: number;
  rels: any[];
  busy: Subscription;

  constructor(private ar: ActivatedRoute, private router: Router, public realCompetitionService: RealCompetitionService) {
    this.pathList = [['RealCompetitions', 'real_competition/list']];
  }
  
  loga($event) {
    this.pathList.push($event);
  }

  ngOnInit(): void {
    this.rels = [];
    this.id = parseInt(this.ar.snapshot.paramMap.get('id'), 10);
    this.single = new RealCompetition();
  }

}
