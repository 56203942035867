<app-breadcrumb [pathList]="pathList"></app-breadcrumb>
<div [ngBusy]="{busy: busy, message: 'Loading...', backdrop: false, delay: 0, minDuration: 100}"></div>
<div class="container-fluid">
    <div class="row d-flex justify-content-between" [formGroup]="searchForm">
        <div class="input-group input-group-sm  mb-1 item d-flex justify-content-start filtering">
            <input type="hidden" [formControlName]="'ctrl_page'" class="form-control">
            <input type="text" [formControlName]="'ctrl_id'" class="form-control" placeholder="id" />
            <input type="text" [formControlName]="'ctrl_description'" class="form-control" placeholder="description" />
            <select [formControlName]="'ctrl_realcompetition_id'" class="form-select" id="inputGroupSelect01" (change)="getList()"  >
                <option value="0" selected>Scegli la competizione...</option>
                <option *ngFor="let rcomp of realCompetition_list" [value]="rcomp.id" [selected]="rcomp.id == searchForm.value['ctrl_realcompetition_id']" > {{rcomp.description}}</option>    
            </select>
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="getList()">Filtra</button>
            </div>
        </div>
        <div class="input-group input-group-sm mb-1 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="navigateTo(0)">
                    <fa-icon [icon]="faPlus" [spin]="false"></fa-icon> Aggiungi
                </button>
            </div>
        </div>
    </div>
    <app-list-realclub [list]="list" [paginationItems]="listPagination" [cols]="cols" [componentService]="realClubService" [routePart]="'real_club'" 
        (page)="page_nr($event)" (reloadList)="getList()" ></app-list-realclub>
    <app-alertbox #alertbox1 [alerts]=""></app-alertbox>
</div>

<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)">asd</app-confirmation-dialog>