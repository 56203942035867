import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { RealCompetitionService } from 'src/app/services/real-competition.service';

@Component({
  selector: 'app-realcompetition-list',
  templateUrl: './realcompetition-list.component.html',
  styleUrls: ['./realcompetition-list.component.scss']
})
export class RealcompetitionListComponent implements OnInit {

  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  list: any;
  faPlus = faPlusCircle;
  faTrash = faTrashAlt;

  cols: string[];
  searchForm = this.fb.group({});

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;

  constructor(private router: Router, public realCompetitionService: RealCompetitionService, private fb: FormBuilder) {
    this.pathList = [['Real Competitions', 'real_competition/list']];
  }

  formControlsNames(form) {
    return Object.keys(form);
  }

  add(a: Alert) {
    this.alertbox1.alerts.push(a);
  }

  getList() {
    let filter: Array<any> | null = null;
    if(this.searchForm.value) {
      filter = [];
    }
    for (const key in this.searchForm.value) {
      if (Object.prototype.hasOwnProperty.call(this.searchForm.value, key)) {
        const element = this.searchForm.value[key];
        const itemKey = key.split('_')[1]
        filter[itemKey] = element;
      }
    }
    this.busy = this.realCompetitionService.list2(filter).subscribe({
      next: (data) => {
        this.list = data.payload;
        // this.cols = data.admin_info.list_cols ?? data.admin_info.cols;
        this.cols = [ "id", "description", "currentSeason_startDate", "currentSeason_endDate" ];
        for (const col of this.cols) {
          this.searchForm.addControl('ctrl_' + col, new FormControl('', Validators.required))
        }
      },
      error: (err) => console.log('ERRORE: ' + JSON.stringify(err.error.description)),
    });
  }

  deleteItem(id: number) {
    if (+id > 0) {
      this.busy = this.realCompetitionService.delete(id).subscribe({
        next: () => this.getList(),
        error: (err) => console.log(err),
      });
    }
  }

  navigateTo(id: any) {
    this.router.navigate([`/real_competition/${id}`]);
  }

  dialogConfirm(id) {
    console.log(id);
    if (+id > 0) {
      this.deleteItem(id);
    }
  }

  ngOnInit(): void {
    this.getList();
  }
}
