import { BaseClass } from "./base_class";
import { BaseInterface } from "./base_interface";

export class RealScardSummary extends BaseClass implements BaseInterface {
    controls: any = {
        id: "number",
        realplayer_id: "number",
        realmatch_id: "number",
        real_competition_id: "number",
        matchday: "number",
        ruolo: "string",
        role_position: "number",
        fonte_voti: "number",
        voto: "number",
        titolare: "number",
        da_panchina: "number",
        minuti_giocati: "number",
        rigore_parato: "number",
        rigore_sbagliato: "number",
        gol_fatti: "number",
        assist: "number",
        assist_calcio_piazzato: "number",
        gol_subiti: "number",
        autogol: "number",
        ammonizioni: "number",
        espulsione: "number",
        gol_decisivi_vittoria: "number",
        gol_decisivo_pareggio: "number",
        clean_shit: "number",
        created_at: "Date",
        updated_at: "Date",
        deleted_at: "Date"
    };

    constructor() {
        super();
        this.id = 0;
 
        this.realplayer_id = 0;
        this.realmatch_id = 0;
        this.real_competition_id = 0;
        this.matchday = 0;
        this.ruolo = "";
        this.role_position = 0;
        this.fonte_voti = 0;
        this.voto = 0;
        this.titolare = 0;
        this.da_panchina = 0;
        this.minuti_giocati = 0;
        this.rigore_parato = 0;
        this.rigore_sbagliato = 0;
        this.gol_fatti = 0;
        this.assist = 0;
        this.assist_calcio_piazzato = 0;
        this.gol_subiti = 0;
        this.autogol = 0;
        this.ammonizioni = 0;
        this.espulsione = 0;
        this.gol_decisivi_vittoria = 0;
        this.gol_decisivo_pareggio = 0;
        this.clean_shit = 0;
        this.shirt_number = 0;

        this.updated_at = null;
        this.deleted_at = null;
    }

    /** extra fields */
    realplayer_description: number;
    realplayer_titolarita_potenziale: number;
    
    /** true fields */
    id: number;
    shirt_number: number;
    realplayer_id: number;
    realmatch_id: number;
    real_competition_id: number;
    matchday: number;
    ruolo: string;
    role_position: number;
    fonte_voti: number;
    voto: number;
    titolare: number;
    da_panchina: number;
    minuti_giocati: number;
    rigore_parato: number;
    rigore_sbagliato: number;
    gol_fatti: number;
    assist: number;
    assist_calcio_piazzato: number;
    gol_subiti: number;
    autogol: number;
    ammonizioni: number;
    espulsione: number;
    gol_decisivi_vittoria: number;
    gol_decisivo_pareggio: number;
    clean_shit: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;

}