<div class="row d-flex justify-content-between">
    <div class="input-group input-group-sm  mb-2 item d-flex justify-content-start">
    </div>
    <div class="input-group input-group-sm mb-2 item add-item">
        <div class="input-group-append">
            <button class="btn btn-app" type="button" (click)='saveItem()'>
                <i class="fas fa-save"></i>
                Salva
            </button>
        </div>
    </div>
</div>

<!-- {{single|json}} -->
<div class="pr-3 pl-3 mb-5">

    <div class="table-responsive">
        <table class="table table-hover table-sm table-edit" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th scope="col" class="col-3">Descrizione</th>
                    <th scope="col" class="col-9">Valore</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ID</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['id']" id="id" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Club</td>
                    <td> 
                        <select class="form-select" id="inputGroupSelect01" [(ngModel)]="option" (change)="changeClub($event.target.value)">
                            <option value="0">Scegli il Club ...</option>
                            <option *ngFor="let real_club of real_clubs" [value]="real_club.id" >
                                <span *ngIf="real_club.common_mame">
                                    {{real_club.common_mame}} 
                                </span>
                                <span *ngIf="!real_club.common_mame ">
                                     {{real_club.description}} 
                                </span>
                                <!-- ({{ getRealCompetitionByClubID(real_club.id).description }}) -->
                                </option>   
                        </select>
                        <!-- <i>{{ getRealCompetitionByClubID(single['realclub_id']).description }} </i> -->
                    </td>
                </tr>
                <tr>
                    <td>Nome calciatore</td>
                    <td>
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="single['calciatore']" id="calciatore">
                    </td>
                </tr>
                <tr>
                    <td>Numero di maglia</td>
                    <td>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]="single['shirtNumber']" id="shirtNumber">
                </tr>
                <tr>
                    <td>Nazionalita</td>
                    <td>
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="single['nazionalita']" id="nazionalita">
                    </td>
                </tr>
                <tr>
                    <td>Ruolo</td>
                    <td>
                        <select class="form-select" id="inputGroupSelect01" [(ngModel)]="single['ruolo']" >
                            <option value="" selected>Scegli il Ruolo ...</option>
                            <option [value]="'P'" [selected]="single['ruolo'] == 'P'">Portiere</option>
                            <option [value]="'D'" [selected]="single['ruolo'] == 'D'">Difensore</option>
                            <option [value]="'C'" [selected]="single['ruolo'] == 'C'">Centrocampista</option>
                            <option [value]="'A'" [selected]="single['ruolo'] == 'A'">Attaccante</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Ruoli specifici</td>
                    <td>
                        <div class="checkbox-container">
                            <div class="checkbox-group">
                                <div class="group-title">Difesa</div>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[0]" (ngModelChange)="onSelectResult('P')" (click)="onCheckboxChanged($event)" /> Portiere</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[1]" (ngModelChange)="onSelectResult('DC')" (click)="onCheckboxChanged($event)" /> Difensore Centrale</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[2]" (ngModelChange)="onSelectResult('DD')" (click)="onCheckboxChanged($event)" /> Difensore (Laterale) Destro</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[3]" (ngModelChange)="onSelectResult('DS')" (click)="onCheckboxChanged($event)" /> Difensore (Laterale) Sinistro</label>
                            </div>
                            
                            <div class="checkbox-group">
                                <div class="group-title">Centrocampo</div>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[4]" (ngModelChange)="onSelectResult('CC')" (click)="onCheckboxChanged($event)" /> Centrocampista Centrale</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[5]" (ngModelChange)="onSelectResult('CF')" (click)="onCheckboxChanged($event)" /> Centrocampista di Fascia</label>
                            </div>

                            <div class="checkbox-group">
                                <div class="group-title">Attacco</div>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[6]" (ngModelChange)="onSelectResult('W')" (click)="onCheckboxChanged($event)" /> Ala</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[7]" (ngModelChange)="onSelectResult('T')" (click)="onCheckboxChanged($event)" /> Trequartista</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[8]" (ngModelChange)="onSelectResult('PC')" (click)="onCheckboxChanged($event)" /> Punta Centrale</label>
                                <label><input class="form-check-input" type="checkbox" [(ngModel)]="isChecked[9]" (ngModelChange)="onSelectResult('A')" (click)="onCheckboxChanged($event)" /> Attaccante</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Data di nascita</td>
                    <td><input type="date" class="form-control form-control-sm"
                            [ngModel]="single['data_nascita'] | date:'yyyy-MM-dd'"
                            (ngModelChange)="single['data_nascita'] = $event" name="data_nascita"></td>
                </tr>
                <tr>
                    <td>Tiratore angoli
                        <br><small>legenda</small>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['tiratore_angoli']" id="tiratore_angoli"  >
                    </td>
                </tr>
                <tr>
                    <td>Tiratore punizioni indirette
                        <br><small>legenda</small>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['tiratore_punizioni_indirette']" id="tiratore_punizioni_indirette"  >
                    </td>
                </tr>
                <tr>
                    <td>Tiratore punizioni</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['tiratore_punizioni']" id="tiratore_punizioni"  >
                    </td>
                </tr>
                <tr>
                    <td>Tiratore rigori</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['tiratore_rigori']" id="tiratore_rigori" >
                    </td>
                </tr>
                <tr>
                    <td>Titolarita potenziale</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['titolarita_potenziale']" id="titolarita_potenziale">
                    </td>
                </tr>
                <tr>
                    <td>VDM transfermarkt</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['vdm_transfermarkt']" id="vdm_transfermarkt" >
                    </td>
                </tr>
                <tr>
                    <td>VDM pmc</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['vdm_pmc']" id="vdm_pmc" >
                    </td>
                </tr>
                <tr>
                    <td>Ingaggio pmc</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['ingaggio_pmc']" id="ingaggio_pmc" >
                    </td>
                </tr>
                <tr>
                    <td>Presenze da titolare</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['presenze_da_titolare']" id="presenze_da_titolare" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Presenze da panchina</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['presenze_da_panchina']" id="presenze_da_panchina" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Media voto</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['media_voto']" id="media_voto" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Media voto fantasy</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['fantasy_media']" id="fantasy_media" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Gialli</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['gialli']" id="gialli" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Rossi</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['rossi']" id="rossi" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Gol</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['gol']" id="gol" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Assist</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['assist']" id="assist" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Rigori parati</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['rigori_parati']" id="rigori_parati" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Goal subiti</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['gol_subiti']" id="gol_subiti" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Autogol</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['autogol']" id="autogol" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Punteggio iniziale</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['punteggio_iniziale']" id="punteggio_iniziale" disabled>
                    </td>
                </tr>
                <tr>
                    <td>Puteggio progressivo</td>
                    <td>
                        <input class="form-control form-control-sm" type="number" [(ngModel)]="single['puteggio_progressivo']" id="puteggio_progressivo" disabled>
                    </td>
                </tr>


                <tr *ngFor="let r of rels ">
                    <!-- <td>
                    <div class="form-check"><input type="checkbox" class="form-check-input"></div>
                    </td> -->
                    <td>{{r.class_name | uppercase}}</td>
                    <td>
                        <!-- <select id="input_{{r.class_name}}" class="form-control"  [(ngModel)]="single[r.class_name + '_id']">
                            <option value='-1'>... </option>
                            <option *ngFor="let opt of getRelated(r.class_name).list "  [ngValue]="opt.id" [attr.selected]="opt.id == single[r.class_name + '_id']? 'checked' : null">
                                {{opt.description}}</option>
                        </select> -->

                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-between">
        <div class="input-group input-group-sm  mt-2 item d-flex justify-content-start">
        </div>
        <div class="input-group input-group-sm mt-2 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)='saveItem()'>
                    <i class="fas fa-save"></i>
                    Salva
                </button>
            </div>
        </div>
    </div>

</div>

<app-alertbox #alertbox1 [alerts]=""></app-alertbox>