<app-breadcrumb [pathList]="pathList"></app-breadcrumb>
<div [ngBusy]="{busy: busy, message: 'Loading...', backdrop: false, delay: 0, minDuration: 100}"></div>
<div class="container-fluid" *ngIf="cols && cols.length > 0">
    <div class="row d-flex justify-content-between" [formGroup]="searchForm">
        <div class="input-group input-group-sm  mb-1 item d-flex justify-content-start filtering">            
            <input type="text" [formControlName]="col" class="form-control" placeholder="{{col.split('_')[1]}}"
                aria-label="Filtra per " aria-describedby="basic-addon2" *ngFor="let col of formControlsNames(searchForm.controls)" >
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="getList()">Filtra</button>
            </div>
        </div>
        <div class="input-group input-group-sm mb-1 item add-item">
            <div class="input-group-append">
                <button class="btn btn-app" type="button" (click)="navigateTo(0)">
                    <fa-icon [icon]="faPlus" [spin]="false"></fa-icon> Aggiungi
                </button>
            </div>
        </div>
    </div>

    <app-list [list]="list" [cols]="cols" [componentService]="userService" [routePart]="'user'"
        (reloadList)="getList()"></app-list>

    <app-alertbox #alertbox1 [alerts]=""></app-alertbox>
</div>
<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)"></app-confirmation-dialog>