import { adminInfo } from './adminInfo';
import { BaseClass } from './base_class';
import { BaseInterface } from './base_interface';
export class RealCompetition extends BaseClass implements BaseInterface {

  constructor() {
    super();
    this.id = 0;
    this.description = "";
    this.fd_id = 0;
    this.fd_currentSeason_id = 0;
    this.currentSeason_startDate = "";
    this.currentSeason_endDate = "";
    this.created_at = null;
    this.updated_at = null;
    this.deleted_at = null;
    this.controls = {
      id: "number",
      description: "string",
      fd_id: "number",
      fd_currentSeason_id: "number",
      currentSeason_startDate: "string",
      currentSeason_endDate: "string",
      created_at: "Date",
      updated_at: "Date",
      deleted_at: "Date",
    }
  }

  controls: any = {
    id: "number",
    description: "string",
    fd_id: "number",
    fd_currentSeason_id: "number",
    currentSeason_startDate: "string",
    currentSeason_endDate: "string",
    created_at: "Date",
    updated_at: "Date",
    deleted_at: "Date",
  }

  id: number;
  description: string;
  fd_id: number;
  fd_currentSeason_id: number;
  currentSeason_startDate: string;
  currentSeason_endDate: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;

}

export class RealCompetitionResponse {
  payload: RealCompetition;
  admin_info: adminInfo;
}
