import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../classes/apiResponse';
import { FilterRealMatch } from '../classes/filterRealMatch';

@Injectable({
  providedIn: 'root'
})
export class RealMatchService  extends BaseService {

  constructor(http: HttpClient) {
    super(http);
    this.entity = 'realmatch';

  }

  private filterObs$: BehaviorSubject<FilterRealMatch> = new BehaviorSubject(new FilterRealMatch());

  getFilterObs(): Observable<FilterRealMatch> {
      return this.filterObs$.asObservable();
  }

  setFilterObs(filter: FilterRealMatch) {
      this.filterObs$.next(filter);
  }

  matchdaylist(rcomp_id=6): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${this.api}/${this.entity}/${rcomp_id}/matchday?limit=50`, this.options);
  }
}
