<div>
    <div class="table-responsive">
        <pagination *ngIf="list?.payload" [totalItems]="list?.pagination.total" [itemsPerPage]="10" [maxSize]="maxSize"  [rotate]="rotate"
            (pageChanged)="pageChanged($event)" [(ngModel)]="list?.pagination.current_page"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
            class="d-flex justify-content-center"></pagination>
        <table class="table table-responsive table-striped table-hover table-list" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <th>#</th>
                    <th>Club in casa</th>
                    <th>Club ospite</th>
                    <th>Giornata</th>
                    <th>Orario</th>
                    <th>Punteggio</th>
                    <th>Stato</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of list?.payload">
                    <td>{{item['id']}} </td>
                    <td>{{item['realclubhome']['common_mame']}} </td>
                    <td>{{item['realclubvisitor']['common_mame']}} </td>
                    <td>{{item['matchday']}} </td>
                    <td>{{item['utcDate'] | date:'dd-MM-yyyy HH:mm' }} </td>
                    <td>
                        {{item['score_fullTime']['home']}}
                        {{item['score_fullTime']['homeTeam']}} - 
                        {{item['score_fullTime']['away']}}
                        {{item['score_fullTime']['awayTeam']}} 
                    </td>
               
                    <td class="actions">
                        <button class="btn btn-app btn-sm btn-edit" type="button" (click)="navigateTo(item?.id)">
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </button>
                        <button class="btn btn-app btn-sm btn-trash" type="button" (click)="one.openModal(item?.id)">
                            <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination *ngIf="list?.payload" [totalItems]="list?.pagination.total" [itemsPerPage]="10" [maxSize]="maxSize"  [rotate]="rotate"
            (pageChanged)="pageChanged($event)" [(ngModel)]="list?.pagination.current_page"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
            class="d-flex justify-content-center"></pagination>
    </div>
</div>
<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)"></app-confirmation-dialog>
<app-alertbox #alertbox1 [alerts]=""></app-alertbox>