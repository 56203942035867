import { adminInfo } from './adminInfo';
import { BaseClass } from './base_class';
import { BaseInterface } from './base_interface';
import { Session } from './session';

export class User extends BaseClass implements BaseInterface {

  constructor() {
    super();
    this.description = "";
    this.nome = "";
    this.id = 0;
    this.cognome = "";
    this.data_di_nascita = null;
    this.comune_residenza = "";
    this.provincia_residenza = "";
    this.lingua = "";
    this.indirizzo_email = "";
    this.squadra_del_cuore = "";
    this.nickname = "";
    this.data_registrazione = null;
    this.prezzo = 0;
    this.ruolo = 0;
    this.admin = 0;
    this.user_admin_id = 0;
    this.subscriptiontype_id = 0;
    this.controls = {
      id: "number",
      session_token: "text",
      authdata: "text",
      email: "text",
      subscriptiontype_id: "number",
      subscriptiontype: "any",
      description: "text",
      nome: "text",
      cognome: "text",
      data_di_nascita: "Date",
      comune_residenza: "text",
      provincia_residenza: "text",
      lingua: "text",
      indirizzo_email: "text",
      squadra_del_cuore: "text",
      nickname: "text",
      data_registrazione: "Date",
      password: "hidden",
      prezzo: "number",
      ruolo: "number",
      user_admin_id: "number",
      clubs: "any",
      leagues: "any",
      admin: "number",
      enabled: "checkbox",
      privacy_accettata: "checkbox",
      privacy_updated_at: "Date"
    }
  }

  controls: any = {
    id: "number",
    session_token: "text",
    authdata: "text",
    email: "text",
    subscriptiontype_id: "number",
    subscriptiontype: "any",
    description: "text",
    nome: "text",
    cognome: "text",
    data_di_nascita: "Date",
    comune_residenza: "text",
    provincia_residenza: "text",
    lingua: "text",
    indirizzo_email: "text",
    squadra_del_cuore: "text",
    nickname: "text",
    data_registrazione: "Date",
    password: "hidden",
    prezzo: "number",
    ruolo: "number",
    user_admin_id: "number",
    clubs: "any",
    leagues: "any",
    admin: "number",
    enabled: "checkbox",
    privacy_accettata: "checkbox",
    privacy_updated_at: "Date"
  }

  id: number;
  session_token: string;

  authdata: string;
  email: string;

  subscriptiontype_id: number;
  subscriptiontype: any;
  description: string;
  nome: string;
  cognome: string;
  data_di_nascita: Date;
  comune_residenza: string;
  provincia_residenza: string;
  lingua: string;
  indirizzo_email: string;
  squadra_del_cuore: string;
  nickname: string;
  data_registrazione: Date;
  prezzo: number;
  ruolo: number;
  user_admin_id: number;
  clubs: any;
  leagues: any;
  admin: number;
  privacy_updated_at: Date;


}

export class UserResponse {
  payload: User;
  admin_info: adminInfo;
}
