import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpDataService {
  private basePath = environment.basePath;
  private _isLogged: boolean;
  private dataFrom: any;
  private dataTo: any;

  constructor(private httpdata: HttpClient, private gs: GlobalService) {
    this._isLogged = false;
  }

  login(username: string, password: string) {
    const body = `{"user": "${username}", "password": "${password}"}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Authorization': 'my-auth-token'
        })
      };
    return this.httpdata.post(this.basePath + '/login', body, httpOptions);
    // .toPromise()
    // .then(c => {
    //   this._isLogged = true;
    //   // console.log(c);
    //   return c;
    // })
    // .catch(e => { console.log('errore', e); this._isLogged = false; });
  }

  logout() {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    return this.httpdata.post(this.basePath + '/logout', headers); //.toPromise()
    // .then(c => { this._isLogged = false; })
    // .catch(e => { console.log(e); this._isLogged = false; });
  }

  isLogged(): boolean {
    return true;
    // return this._isLogged;
  }

  setLogged(status: boolean) {
    this._isLogged = status;
  }

  // search(filter: Filters) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };

  //   let obj = '';

  //   for (const f of filter.elements) {
  //     obj = obj + f.filter + '/' + f.val + '/';
  //   }

  //   return this.httpdata.get(this.basePath + '/' + obj, httpOptions).toPromise();
  // }

  // CRUD
  // create(tableName: string, record: object) {
  //   var body = JSON.stringify(record);
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   let options = new RequestOptions({ headers: headers, withCredentials: true });
  //   return this.httpdata.post(this.basePath + '/' + tableName + '/', body, options).toPromise();
  // }
  // readAll(tableName: string) {
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   let options = new RequestOptions({ headers: headers, withCredentials: true });
  //   return this.httpdata.get(this.basePath + '/' + tableName, options).toPromise();
  // }

  post(action: string, record: object) {
    const body = JSON.stringify(record);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpdata.post(this.basePath + '/' + action, body, httpOptions);
  }

  get(queryString: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // console.log(this.basePath + queryString);
    return this.httpdata.get(this.basePath + queryString, httpOptions);

  }

  getAll(queryString: string) {
    return this.httpdata.get(this.basePath + queryString);
  }


  update(tableName: string, id: number, record: object) {
    const body = JSON.stringify(record);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.httpdata.put(this.basePath + '/' + tableName + '/' + id, body);
  }

  // delete(tableName: string, id: number) {
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   let options = new RequestOptions({ headers: headers, withCredentials: true });
  //   return this.httpdata.delete(this.basePath + '/' + tableName + '/' + id, options);
  // }
}
