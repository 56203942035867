<div class="row page-titles">
  <div class="col-md-12">
    <div class="bread-and-date clearfix">
      <div class="float-left">
        <ol class="breadcrumb d-none d-sm-block">
          <li class="breadcrumb-item" *ngFor="let pl of pathListValue; i as index; let l = last">
            <span *ngIf="!l"><a routerLink="/{{pl[1]}}">{{pl[0]|uppercase}}</a></span>
            <span *ngIf="l">{{pl[0]|uppercase}}</span>
          </li>
        </ol>
      </div>

      <div class="float-right">
        <span *ngFor='let e of bcRight'>
          <a href="" class="btn btn-app" *ngIf='e[1]==1'>
            <!-- <i class="fa fa-calendar-plus"></i> -->
            <i class="fas fa-users"></i>
            <span class="d-none d-sm-block">Crea Scheda Attività</span>
          </a>
          <a href="" class="btn btn-app" *ngIf='e[1]==2'>
            <i class="fas fa-map-pin"></i>
            <span class="d-none d-sm-block">Crea Scheda Sede</span>
          </a>
          <a href="" class="btn btn-app" *ngIf='e[1]==3'>
            <i class="fas fa-calendar-plus"></i>
            <span class="d-none d-sm-block">Crea Scheda Attività Sede</span>
          </a>
        </span>

      </div>
    </div>
  </div>

  <!-- <div class="col-md-12">
    <h3 class="m-b-0 m-t-0">{{pathListValue[pathListValue.length-1][0]|uppercase}}</h3>
  </div> -->
</div>

<div class="row">
  <div class="col-md-12">
    <h1 class="page-title">
      {{getTitle()|uppercase}}
    </h1>
  </div>
</div>
