import { tap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalService } from './services/global.service';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public userService: UserService, private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sst = this.userService.getCurrentUser() ? this.userService.getCurrentUser().session_token : null;
    req = req.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        Authorization: `Bearer ${sst}`,
      },
    });

    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          // console.log('---> status:', evt.status);
          // console.log('---> filter:', req.params.get('filter'));
        }
      }, (err: HttpResponseBase) => {
        console.log(err);
        if (err.status == 401) {
          this.router.navigateByUrl('/login');
        } 
      })
    );
  }
}
