import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Subscription, forkJoin } from 'rxjs';
import { Alert } from 'src/app/classes/alert';
import { FilterRealMatch } from 'src/app/classes/filterRealMatch';
import { RealCompetition } from 'src/app/classes/real_competition';
import { AlertboxComponent } from 'src/app/general/alertbox/alertbox.component';
import { RealCompetitionService } from 'src/app/services/real-competition.service';
import { RealMatchService } from 'src/app/services/real-match.service';

@Component({
  selector: 'app-realmatch-list',
  templateUrl: './realmatch-list.component.html',
  styleUrls: ['./realmatch-list.component.scss']
})
export class RealmatchListComponent implements OnInit {

  subscription: any;
  pathList: Array<Array<string>>;
  bcRightValue: Array<Array<number>>;
  busy: Subscription;
  list: any;
  matchdays: any;
  matchdays_pl: any;
  faPlus = faPlusCircle;
  faTrash = faTrashAlt;
  filterRealMatch: FilterRealMatch;

  cols: string[];
  searchForm = this.fb.group({});

  @ViewChild('alertbox1', { static: false }) alertbox1: AlertboxComponent;
  unsubscribe$: Subscription;
  realCompetition_list: RealCompetition[];

  constructor(private router: Router, public realMatchService: RealMatchService, public realCompetitionService: RealCompetitionService, private fb: FormBuilder) {
    this.pathList = [['Real Matches', 'real_match/list']];
  }

  realcometitionpage($event) {
    this.searchForm.controls['ctrl_realcompetition_id'].setValue($event);
    this.busy = this.realMatchService.matchdaylist($event).subscribe(rmatchdays => {
      for (const item of rmatchdays['payload']) {
        item['cal_day'] = item['cal_day'] + 'Z';
      }
      this.matchdays_pl = rmatchdays['payload'];
      this.getList();
    });
  }

  matchpage($event) {
    // ctrl_matchday
    this.searchForm.controls['ctrl_matchday'].setValue($event);
    this.getList();
  }

  page_nr($event) {
    this.searchForm.controls['ctrl_page'].setValue($event);
    this.getList();
  }

  formControlsNames(form) {
    return Object.keys(form);
  }

  add(a: Alert) {
    this.alertbox1.alerts.push(a);
  }

  getList() {
    let filter: Array<any> | null = null;
    if (this.searchForm.value) {
      filter = [];
    }
    this.filterRealMatch = new FilterRealMatch();
    for (const key in this.searchForm.value) {
      if (Object.prototype.hasOwnProperty.call(this.searchForm.value, key)) {
        const element = this.searchForm.value[key];
        if ((element || element == '') && element != 'null') {
          const itemKey = key.split('_')[1]
          filter[itemKey] = element;
          this.filterRealMatch['ctrl_' + itemKey.replace(".", "")] = element;
        }
      }
    }

    this.realMatchService.setFilterObs(this.filterRealMatch);

    this.busy = this.realMatchService.list2(filter).subscribe(rmatch => {
      for (const item of rmatch.payload) {
        try {
          item['utcDate'] = new Date(item['utcDate'] + 'Z');
          if (!item['score_fullTime']){
            item['score_fullTime'] = { "awayTeam": 0, "homeTeam": 0 };
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.list = rmatch;
    });

  }

  deleteItem(id: number) {
    if (+id > 0) {
      this.busy = this.realMatchService.delete(id).subscribe({
        next: () => this.getList(),
        error: (err) => console.log(err),
      });
    }
  }

  navigateTo(id: any) {
    this.router.navigate([`/real_match/${id}`]);
  }

  dialogConfirm(id) {
    if (+id > 0) {
      this.deleteItem(id);
    }
  }

  ngOnInit(): void {
    this.cols = ["id", "realclubhome.description", "realclubvisitor.description", "matchday", "score_fullTime", "status"];
    this.searchForm.addControl('ctrl_page', new FormControl('', Validators.required))
    this.searchForm.addControl('ctrl_realcompetition_id', new FormControl('', Validators.required))
    for (const col of this.cols) {
      this.searchForm.addControl('ctrl_' + col, new FormControl('', Validators.required))
    }

    this.busy = this.realCompetitionService.list2().subscribe((rcompetitions) => {
      this.realCompetition_list = rcompetitions.payload.sort((a, b) => (a.description < b.description ? -1 : 1));
      this.realcometitionpage(this.searchForm.controls['ctrl_realcompetition_id'].value);
    });

    this.unsubscribe$ = this.realMatchService.getFilterObs().subscribe(filter => {
      this.filterRealMatch = filter;
      this.searchForm.controls['ctrl_matchday'].setValue(this.filterRealMatch.ctrl_matchday);
      this.searchForm.controls['ctrl_realcompetition_id'].setValue(this.filterRealMatch.ctrl_realcompetition);
      this.searchForm.controls['ctrl_id'].setValue(this.filterRealMatch.ctrl_id);
      this.searchForm.controls['ctrl_page'].setValue(this.filterRealMatch.ctrl_page);
      this.searchForm.controls['ctrl_realclubhome.description'].setValue(this.filterRealMatch.ctrl_realclubhomedescription);
      this.searchForm.controls['ctrl_realclubvisitor.description'].setValue(this.filterRealMatch.ctrl_realclubvisitordescription);
      this.searchForm.controls['ctrl_status'].setValue(this.filterRealMatch.ctrl_status);

    });
    this.getList();

  }


}
