export class FilterRealMatch {
    ctrl_page: number;
    ctrl_matchday: number;
    ctrl_realcompetition: number;
    ctrl_id: number;
    ctrl_realclubhomedescription: string;
    ctrl_realclubvisitordescription: string;
    ctrl_status: string;
    constructor(){
        this.ctrl_matchday = 0;
        this.ctrl_realcompetition = 0;
    }
}