<div>
    <div class="table-responsive">
        <pagination *ngIf="list" [totalItems]="paginationItems.total" [itemsPerPage]="paginationItems.per_page" [(ngModel)]="paginationItems.current_page"
            (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
            [rotate]="rotate" [maxSize]="maxSize" class="d-flex justify-content-center"></pagination>
        <!-- <span *ngIf="returnedArray">
            {{returnedArray}}
        </span> -->
        <table class="table table-responsive table-striped table-hover table-list" id="defaultCheck1">
            <thead class="thead-default">
                <tr>
                    <!--<th *ngFor="let c of cols "><div class="text-truncate">{{c}}</div></th>-->
                    <!-- <td *ngFor="let r of list?.rels ">{{r}}</td> -->
                    <th *ngFor="let c of cols ">
                        <div class="truncate">
                            <span class="truncated">{{c}}</span>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of returnedArray">
                    
                    <td *ngFor="let c of cols ">
                        <span *ngIf="c.indexOf('.') != -1">
                            {{item[c.split('.')[0]][c.split('.')[1]]}}
                        </span>
                        <span *ngIf="c.indexOf('.') == -1">
                            {{item[c]}}
                        </span>
                    </td>
                    <!-- <td *ngFor="let r of list?.rels ">{{u[r]}}</td> -->
                    <td class="actions">
                        <button class="btn btn-app btn-sm btn-edit" type="button" (click)="navigateTo(item?.id)">
                             <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </button>
                        <button class="btn btn-app btn-sm btn-trash" type="button" (click)="one.openModal(item?.id)">
                             <fa-icon [icon]="faTrashAlt"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <pagination *ngIf="list" [totalItems]="paginationItems.total" [itemsPerPage]="paginationItems.per_page" [(ngModel)]="paginationItems.current_page" 
            (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
            [rotate]="rotate" [maxSize]="maxSize" class="d-flex justify-content-center"></pagination>
    </div>
</div>
<app-confirmation-dialog #one (dialogOutput)="dialogConfirm($event)"></app-confirmation-dialog>
<app-alertbox #alertbox1 [alerts]=""></app-alertbox>
