import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RealClub } from 'src/app/classes/real_club';
import { RealClubService } from 'src/app/services/real-club.service';

@Component({
  selector: 'app-realclub-single',
  templateUrl: './realclub-single.component.html',
  styleUrls: ['./realclub-single.component.scss']
})
export class RealclubSingleComponent implements OnInit {
  pathList: string[][];
  single: RealClub;
  id: number;
  rels: any[];
  busy: Subscription;

  constructor(private ar: ActivatedRoute, private router: Router, public realClubService: RealClubService) {
    this.pathList = [['RealClubs', 'real_club/list']];
  }
  
  loga($event) {
    this.pathList.push($event);
  }

  ngOnInit(): void {
    this.rels = [];
    this.id = parseInt(this.ar.snapshot.paramMap.get('id'), 10);
    this.single = new RealClub();
  }

}
